import { notification } from "antd";

const Notifications = {
	show: (type: any, message: any, description: any) => {
		// notification config
		notification.config({
			placement: "bottomRight",
			bottom: 50,
		});

		const _message = message ? message : null;
		const _description = description ? description : null;

		type === "success"
			? notification.success({
					message: _message ? _message : "Success!",
					description: _description,
			  })
			: type === "info"
			? notification.info({
					message: _message ? _message : "Info!",
					description: _description,
			  })
			: type === "warning"
			? notification.warning({
					message: _message ? _message : "Warning!",
					description: _description,
			  })
			: type === "error" &&
			  notification.error({
					message: _message ? _message : "Error!",
					description: _description,
			  });
	},
};

export default Notifications;
