import axios from 'axios';
import { add } from 'cheerio/lib/api/traversing';

const NewswireController: any = {
    getNewswires: () => {
        return new Promise((resolve: any, reject: any) => {
            axios.get(`/api/newswire`)
                .then((response: any) => {
                    resolve(response.data);
                    return response.data;
                })
                .catch((error: any) => {
                    reject(error);
                    return error;
                });
        });
    },

    deleteNewswire: (ids: any) => {
        console.log("delete newswire ids", ids)
        return new Promise((resolve: any, reject: any) => {
            axios.post(`/api/newswire/delete`, ids).then((response: any) => {
                resolve(response.data)
            }).catch((error: any) => {
                reject(error)
            })
        })
    }
}

export default NewswireController;