import { CheckCircleOutlined } from "@ant-design/icons";
import { Modal, Table, Space, Input, Typography } from "antd";
import { useEffect } from "react";

const DistributorsStatusModal = (props: any) => {
	const distribution = props.articleRecord;

	useEffect(() => {
		console.log("distribution", distribution);
	}, []);

	const columns: any = [
		{
			title: "Distribution Date",
			dataIndex: "date",
			key: "date",
			width: "30%",
		},
		{
			title: "Distribution Status",
			dataIndex: "distributors",
			key: "distributors",
			render: (names: any) => (
				<>
					<Space direction="vertical">
						{names.map((names: any, index: any) => (
							<Space key={index} style={{ color: "green" }}>
								<CheckCircleOutlined />
								{names}
							</Space>
						))}
					</Space>
				</>
			),
		},
	];

	const data: any = Object.keys(distribution.distribution_status).map(
		(key: any) => {
			return {
				key: key,
				date: key,
				distributors: distribution.distribution_status[key],
			};
		}
	);
	data.reverse(); // reverse the data to show the latest distribution status first
	// display distribution data
	useEffect(() => {
		console.log("distribute status", data);
	}, []);

	return (
		<Modal
			title="Distribution Information"
			open={props.open}
			onCancel={props.onCancel}
			footer={null}
			width={700}
		>
			<Space
				direction="vertical"
				style={{ marginBottom: "20px", width: "100%" }}
				size={30}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<span
						style={{
							fontWeight: "bold",
							// marginRight: "30px",
							padding: "0px 20px",
						}}
					>
						Title
					</span>
					<div
						style={{
							border: "1px solid #ccc",
							padding: "8px",
							width: "100%",
						}}
					>
						<Typography.Text>{distribution.title}</Typography.Text>
					</div>
				</div>
				<Table columns={columns} dataSource={data} pagination={false} />
			</Space>
		</Modal>
	);
};

export default DistributorsStatusModal;
