import { Button, Form, Input, notification } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../App.css";
import axios from "axios";
import ArrnLogo from "../assets/img/Logo3-removebg-preview.png";

const ResetPasswordPage = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const items = [
		{
			key: "password",
			name: "password",
			rules: [
				{ required: true, message: "Please input your password!" },
				{ min: 8, message: "Password must be at least 8 characters!" },
				// password must contain at least one uppercase letter, one lowercase letter, one number, and one special character
				{
					validator: (rule: any, value: any) => {
						if (
							!value.match(
								/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/
							)
						) {
							return Promise.reject(
								"Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character!"
							);
						} else {
							return Promise.resolve();
						}
					},
				},
			],
			validateTrigger: "onBlur",
			component: (
				<div className="input-form">
					<label className="label-form">Password</label>
					<Input.Password placeholder="Please Enter New Password" />
				</div>
			),
		},
		{
			key: "confirmPassword",
			name: "confirmPassword",
			rules: [
				{ required: true, message: "Please confirm your password!" },
				{
					// validate the password and confirm password
					validator: (rule: any, value: any) => {
						if (value !== form.getFieldsValue().password) {
							return Promise.reject("Passwords do not match!");
						} else {
							return Promise.resolve();
						}
					},
				},
			],
			validateTrigger: "onBlur",
			component: (
				<div className="input-form">
					<label className="label-form">Confirm Password</label>
					<Input.Password placeholder="Please Confirm Password" />
				</div>
			),
		},
	];

	const onFinish = (values: any) => {
		const id = searchParams.get("id");
		console.log("Reset password:", values, "id:", id);
		return new Promise((resolve: any, reject: any) => {
			axios
				.post(
					`/api/users/resetPassword/${id}`,
					values
				)
				.then((res) => {
					console.log(res);
					resolve(res);
					if (res.data.success) {
						notification.success({
							message: "Password Reset Successfully!",
							description: "Please login with your new password.",
						});
						navigate("/login");
					} else {
						notification.error({
							message: "Password Reset Failed!",
							description: res.data.message,
						});
					}
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<div className="reset-password-page background-image">
			<div className="logo">
				<img src={ArrnLogo} alt="logo" width={"500vw"}></img>
			</div>
			<div className="form-box">
				<h1>Reset Password</h1>
				<hr style={{ margin: "10px 0px 30px 0px" }} />
				<div style={{ marginBottom: "24px" }}>
					<p>
						Please enter your new password to reset your password.
					</p>
				</div>
				<Form
					form={form}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					style={{}}
				>
					{items.map((item: any) => (
						<Form.Item {...item}>{item.component}</Form.Item>
					))}
					<Form.Item
						style={{
							display: "flex",
							justifyContent: "flex-end",
							marginBottom: "0px",
							width: "100%",
						}}
					>
						<Button type="link" onClick={() => navigate("/login")}>
							Back to Login Page
						</Button>
						<Button
							className="button-green"
							type="primary"
							htmlType="submit"
						>
							Reset Password
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};

export default ResetPasswordPage;
