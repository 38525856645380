import {
	Button,
	Tag,
	Input,
	Form,
	Select,
	notification,
	Table,
	Dropdown,
	Space,
	DatePicker,
	Modal,
} from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import ModalComponent from "../components/Modal";
import {
	SettingOutlined,
	DownOutlined,
	SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import TableComponent from "../components/TableComponent";

const UserManagementPage = () => {
	const [fulldata, setFulldata] = useState<any>([]);
	const [showedData, setShowedData] = useState<any>([]); //data for table
	const [loading, setLoading] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [selectedRow, setSelectedRow] = useState<any>({}); //selected row for username
	const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]); //selected row keys
	const [filter, setFilter] = useState<any>({}); //filter for table
	const [search, setSearch] = useState<any>([]); //search for table
	const [sortedInfo, setSortedInfo] = useState<any>({});
	const [originalData, setOriginalData] = useState<any>({}); //original data berfore editing

	const [form] = Form.useForm();

	useEffect(() => {
		console.log("Before editing", originalData);
	}, [originalData]);

	// function to fetch data from server
	const fetchData = async () => {
		setLoading(true);
		axios.get(`/api/users`).then((res) => {
			const newData = res.data.data.map((req: any) => ({
				key: req._id,
				username: req.username,
				email: req.email,
				approvalStatus: req.approvalStatus,
				role: req.role,
				lastEdit: req.lastEdit ? req.lastEdit : "",
				reason: req.reason,
			}));
			console.log("Data", newData);
			setFulldata(newData);
			setShowedData(newData);
			setLoading(false);
		});
	};

	// fetch data on page load
	useEffect(() => {
		fetchData();
	}, []);

	// always reset form fields when modal closed
	useEffect(() => {
		if (!showModal) {
			form.resetFields();
		}
	}, [showModal]);

	// function to format date to locale string
	const formatDate = (date: any) => {
		let formattedDate = new Date(date);
		return formattedDate.toLocaleString();
	};

	// function to open modal when username clicked
	const handleClickUsername = (record: any) => {
		console.log("Username clicked", record);
		setShowModal(true);
		setOriginalData(record);
		setSelectedRow(record);
	};

	// clear filter
	const clearFilters = () => {
		setFilter({});
		handleTableChange(
			{},
			{},
			{
				column: undefined,
				order: undefined,
				field: "email",
				columnKey: "email",
			}
		);
	};

	// function to handle filter
	const handleFilter = () => {
		if (Object.keys(filter).length === 0) {
			console.log("Filter Cleared", filter);
			setShowedData(fulldata);
			fetchData();
		} else {
			// filter data
			console.log("Filtered columns", filter);
			// for all key that exist in filter, filter the fulldata
			let filteredData = fulldata;
			Object.keys(filter).forEach((key) => {
				console.log("Filter key", key);
				filteredData = filteredData.filter((element: any) =>
					element[key].includes(filter[key])
				);
			});

			console.log("After filtering data", filteredData);
			setShowedData(filteredData);
		}
	};

	// handle filter when filter value changed
	useEffect(() => {
		console.log("Filter changed", filter);
		handleFilter();
	}, [filter]);

	// handle filter when search value changed
	const handleOnChangeFilter = (value: any, field: any) => {
		console.log(`selected ${field}: ${value}`);
		if (value === undefined) {
			// remove the field from the filter
			const newFilter = { ...filter };
			delete newFilter[field];
			console.log("New Filter after remove", newFilter);
			setFilter(newFilter);
		} else {
			setFilter({
				...filter,
				[field]: value,
			});
		}
	};

	// handle filter when clear search value
	const handleOnClearSearch = (field: any) => {
		setSearch(search.filter((item: any) => item !== field));
	};

	// table columns
	const columns: any = [
		{
			key: "username",
			title: () => {
				return (
					<>
						{search.includes("username") ? (
							<Select
								placeholder="Search Username"
								style={{ width: "100%", fontWeight: "bold" }}
								defaultValue={filter["username"]}
								showSearch
								allowClear
								autoFocus={true}
								options={
									// get all username from fulldata
									fulldata.map((item: any) => ({
										value: item.username,
										label: item.username,
									}))
								}
								onChange={(value: any) => {
									handleOnChangeFilter(value, "username");
								}}
								onClear={() => {
									handleOnClearSearch("username");
								}}
								// close the search when not focus
								onBlur={() => {
									handleOnClearSearch("username");
								}}
							/>
						) : (
							<a
								style={{
									fontWeight: "bold",
									color: "#000000",
									display: "flex",
									justifyContent: "space-between",
									width: "100%",
								}}
								onClick={(e: any) => {
									e.stopPropagation();
									setSearch([...search, "username"]);
								}}
							>
								Username
								<span>
									<SearchOutlined />
								</span>
							</a>
						)}
					</>
				);
			},
			dataIndex: "username",
			showSorterTooltip: false,
			sortOrder: sortedInfo.columnKey === "username" && sortedInfo.order,
			sorter: search.includes("username")
				? false
				: (a: any, b: any) => a.username.localeCompare(b.username),
			render: (username: any, record: any) => (
				<a
					onClick={() => {
						handleClickUsername(record);
					}}
				>
					{username}
				</a>
			),
		},
		{
			key: "email",
			title: () => {
				return (
					<>
						{search.includes("email") ? (
							<Select
								placeholder="Search Email"
								showSearch
								allowClear
								autoFocus={true}
								defaultValue={filter["email"]}
								options={
									// get all email from fulldata
									fulldata.map((item: any) => ({
										value: item.email,
										label: item.email,
									}))
								}
								style={{ width: "100%", fontWeight: "bold" }}
								onChange={(value: any) => {
									handleOnChangeFilter(value, "email");
								}}
								onClear={() => {
									handleOnClearSearch("email");
								}}
								onBlur={() => {
									handleOnClearSearch("email");
								}}
							/>
						) : (
							<a
								style={{
									fontWeight: "bold",
									color: "#000000",
									display: "flex",
									justifyContent: "space-between",
									width: "100%",
								}}
								onClick={(e: any) => {
									e.stopPropagation();
									setSearch([...search, "email"]);
								}}
							>
								Email
								<span>
									<SearchOutlined />
								</span>
							</a>
						)}
					</>
				);
			},
			dataIndex: "email",
			showSorterTooltip: false,
			sortOrder: sortedInfo.columnKey === "email" && sortedInfo.order,
			sorter: search.includes("email")
				? false
				: (a: any, b: any) => a.email.localeCompare(b.email),
		},
		{
			key: "approvalStatus",
			title: () => {
				const handleSelect: any = (item: any) => {
					console.log("Item clicked", item);
					setFilter({
						...filter,
						approvalStatus: item.key,
					});
				};

				const items: any = [
					{
						label: "Show All",
						key: "",
						onClick: handleSelect,
					},
					{
						label: "Approved",
						key: "approved",
						onClick: handleSelect,
					},
					{
						label: "Pending",
						key: "pending",
						onClick: handleSelect,
					},
					{
						label: "Rejected",
						key: "rejected",
						onClick: handleSelect,
					},
				];

				return (
					<Dropdown
						menu={{ items, selectable: true }}
						trigger={["click"]}
					>
						<Button
							type="link"
							style={{
								color: "rgba(0,0,0,0.85)",
								fontWeight: "bold",
								width: "100%",
							}}
						>
							<Space
								style={{
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								Status
								<DownOutlined style={{ fontSize: "11px" }} />
							</Space>
						</Button>
					</Dropdown>
				);
			},
			dataIndex: "approvalStatus",
			align: "center",
			width: "10%",
			render: (approvalStatus: any) => {
				let color =
					approvalStatus === "approved"
						? "success"
						: approvalStatus === "pending"
						? "processing"
						: "error";
				return <Tag color={color}>{approvalStatus.toUpperCase()}</Tag>;
			},
		},
		{
			key: "role",
			title: () => {
				const handleSelect: any = (item: any) => {
					console.log("Item clicked", item);
					setFilter({
						...filter,
						role: item.key,
					});
				};

				const items: any = [
					{
						label: "Show All",
						key: "",
						onClick: handleSelect,
					},
					{
						label: "Admin",
						key: "admin",
						onClick: handleSelect,
					},
					{
						label: "User",
						key: "user",
						onClick: handleSelect,
					},
				];

				return (
					<Dropdown
						menu={{ items, selectable: true }}
						trigger={["click"]}
					>
						<Button
							type="link"
							style={{
								color: "rgba(0,0,0,0.85)",
								fontWeight: "bold",
								width: "100%",
							}}
						>
							<Space
								style={{
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								Role
								<DownOutlined style={{ fontSize: "11px" }} />
							</Space>
						</Button>
					</Dropdown>
				);
			},
			dataIndex: "role",
			align: "center",
			width: "10%",
			render: (role: any) => {
				return role.toUpperCase();
			},
		},
		{
			key: "lastEdit",
			title: () => {
				return (
					<>
						{search.includes("lastEdit") ? (
							<DatePicker
								style={{ width: "100%", fontWeight: "bold" }}
								placeholder="Search Last Edit"
								onChange={(date: any, dateString: any) => {
									handleOnChangeFilter(
										dateString,
										"lastEdit"
									);
								}}
								allowClear
								open={true}
								autoFocus={true}
								onBlur={() => {
									handleOnClearSearch("lastEdit");
								}}
								value={
									filter.lastEdit
										? moment(filter.lastEdit)
										: null
								}
							/>
						) : (
							<a
								style={{
									fontWeight: "bold",
									color: "#000000",
									display: "flex",
									justifyContent: "space-between",
									width: "100%",
								}}
								onClick={(e: any) => {
									e.stopPropagation();
									setSearch([...search, "lastEdit"]);
								}}
							>
								Last Edited
								<span>
									<SearchOutlined />
								</span>
							</a>
						)}
					</>
				);
			},
			dataIndex: "lastEdit",
			align: "center",
			width: "20%",
			showSorterTooltip: false,
			sortOrder: sortedInfo.columnKey === "lastEdit" && sortedInfo.order,
			sorter: search.includes("lastEdit")
				? false
				: (a: any, b: any) => {
						// sort by date
						return a["lastEdit"]
							.toString()
							.localeCompare(b["lastEdit"].toString());
				  },
			render: (lastEdit: any) => {
				// format date to locale string
				return lastEdit ? formatDate(lastEdit) : "None";
			},
		},
	];

	useEffect(() => {
		console.log("Selected Row", selectedRowKeys);
	}, [selectedRowKeys]);

	// row selection for table
	const rowSelection = {
		onChange: (selectedRowKeys: any, selectedRows: any) => {
			setSelectedRowKeys(selectedRowKeys);
		},
		// getCheckboxProps: (record: any) => ({
		// 	disabled: record.name === "Disabled User",
		// 	name: record.name,
		// }),
		columnWidth: "1%",
	};

	// modal functions
	const handleCancel = (values: any) => {
		// compare originalData with form
		// if same, close modal
		// if different, show modal
		if (originalData === selectedRow) {
			console.log("no changes made");
			setShowModal(false);
		} else {
			console.log("Unsaved Changes");
			// ask user if they want to discard changes
			Modal.confirm({
				title: "Leave without saving?",
				content:
					"You have unsaved changes. Are you sure you want to leave?",
				okText: "Yes",
				cancelText: "No",
				onOk: () => {
					setShowModal(false);
				},
			});
		}
	};

	const handleOk = async () => {
		form.submit();
	};

	// status and role options in edit form
	const allStatus = [
		{ key: "approved", label: "Approved", value: "approved" },
		{ key: "pending", label: "Pending", value: "pending" },
		{ key: "rejected", label: "Rejected", value: "rejected" },
	];
	const allRoles = [
		{ key: "admin", label: "Admin", value: "admin" },
		{ key: "user", label: "User", value: "user" },
	];

	// input for edit form
	const formData = [
		{
			key: "username",
			name: "username",
			initialValue: selectedRow?.username,
			component: (
				<div className="input-form">
					<label className="label-form">Username</label>
					<Input
						value={selectedRow?.username}
						bordered={false}
						readOnly
					/>
				</div>
			),
		},
		{
			key: "email",
			name: "email",
			initialValue: selectedRow?.email,
			component: (
				<div className="input-form">
					<label className="label-form">Email</label>
					<Input
						value={selectedRow?.email}
						bordered={false}
						readOnly
					/>
				</div>
			),
		},
		{
			key: "approvalStatus",
			name: "approvalStatus",
			initialValue: selectedRow?.approvalStatus,
			component: (
				<div className="input-form">
					<label className="label-form">Status</label>
					<Select
						value={selectedRow?.approvalStatus}
						onChange={(value) => {
							// assign value to form
							form.setFieldsValue({
								approvalStatus: value,
							});
							setSelectedRow({
								...selectedRow,
								approvalStatus: value,
							});
						}}
						options={allStatus}
					/>
				</div>
			),
		},
		{
			key: "reason",
			name: "reason",
			initialValue: selectedRow?.reason,
			rules: [{ required: true, message: "Please state a reason." }],
			component: (
				<div className="input-form">
					<label className="label-form">Reason</label>
					<Input
						placeholder="Enter reason here"
						value={selectedRow?.reason}
						onChange={(e) => {
							// assign value to form
							form.setFieldsValue({
								reason: e.target.value,
							});
							setSelectedRow({
								...selectedRow,
								reason: e.target.value,
							});
						}}
					/>
				</div>
			),
		},
		{
			key: "role",
			name: "role",
			initialValue: selectedRow?.role,
			component: (
				<div className="input-form">
					<label className="label-form">Role</label>
					<Select
						value={selectedRow?.role}
						onChange={(value) => {
							// assign value to form
							form.setFieldsValue({
								role: value,
							});
							setSelectedRow({
								...selectedRow,
								role: value,
							});
						}}
						options={allRoles}
					/>
				</div>
			),
		},
	];

	const handleSubmit = (values: any) => {
		return new Promise((resolve: any, reject: any) => {
			axios
				.put(
					`/api/users/approve`,
					values
				)
				.then((res) => {
					console.log(res);
					resolve(res);
					if (res.data.success) {
						notification.success({
							message: "User updated!",
							description: "User has been updated successfully.",
						});
						// refresh table
						setSelectedRow({});
						setSelectedRowKeys([]);
						fetchData();
					}
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
	};

	// on form submit
	const onFinish = (values: any) => {
		if (
			values.approvalStatus === "rejected" &&
			values.reason.trim() === ""
		) {
			Modal.error({
				title: "Please state a reason.",
				content: "Input cannot be empty.",
			});
			return;
		}
		console.log("Edited data:", values);
		setShowModal(false);
		handleSubmit(values);
	};

	// handle delete user
	const handleDelete = () => {
		let ids = selectedRowKeys;
		console.log("Delete ids:", ids);
		return new Promise((resolve: any, reject: any) => {
			axios
				.post(
					`/api/users/deleteMany`,
					ids
				)
				.then((res) => {
					console.log(res);
					resolve(res);
					if (res.data.success) {
						notification.success({
							message: "User deleted!",
							description: "User has been deleted successfully.",
						});
						// refresh table
						fetchData();
					} else {
						notification.error({
							message: "Failed to delete user!",
							description: "User has not been deleted.",
						});
					}
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
	};

	// confirm delete
	const confirmDelete = () => {
		Modal.confirm({
			title: "Delete User",
			content:
				"Are you sure you want to delete this user? Deleted users cannot be recovered.",
			okText: "Delete",
			onOk() {
				handleDelete();
			},
		});
	};

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		// Handle table change event
		// Update the sortedInfo state with the current sorter state setSortedInfo(sorter);
		console.log("pagination", pagination);
		console.log("filters", filters);
		console.log("sorter", sorter);

		let sortObj: any = {};

		if (sorter.order !== undefined && sorter.order) {
			sortObj[sorter.field] = sorter.order === "ascend" ? 1 : -1;

			setSortedInfo(sorter);
		} else {
			setSortedInfo({});
		}
	};

	return (
		<>
			{showModal && (
				<ModalComponent
					title={
						<>
							<SettingOutlined />
							&nbsp;Edit User
						</>
					}
					open={showModal}
					handleOk={handleOk}
					handleCancel={handleCancel}
					okText="Save"
					cancelText="Cancel"
				>
					<Form form={form} onFinish={onFinish}>
						{formData.map((item: any) => {
							// if item.key === "reason" and selectedRow?.approvalStatus !== "rejected" then show form item
							if (
								item.key === "reason" &&
								selectedRow?.approvalStatus !== "rejected"
							) {
								return null; // do not render this form item
							} else {
								return (
									<Form.Item {...item}>
										{item.component}
									</Form.Item>
								);
							}
						})}
					</Form>
				</ModalComponent>
			)}
			<h1>User Management</h1>
			<div
				style={{
					display: "flex",
					marginBottom: "18px",
					justifyContent: "flex-end",
					gap: "10px",
				}}
			>
				<Button
					type="primary"
					style={{
						display:
							selectedRowKeys.length !== 0 ? "block" : "none",
					}}
					onClick={confirmDelete}
					danger
				>
					Delete
				</Button>
				<Button
					className="button-green"
					style={{
						display:
							Object.keys(filter).length !== 0 ? "block" : "none",
					}}
					onClick={clearFilters}
				>
					Reset
				</Button>
			</div>
			<Table
				columns={columns}
				dataSource={showedData}
				bordered
				rowSelection={rowSelection}
				rowClassName={(record, index) => {
					return index % 2 === 0
						? "table-row-light"
						: "table-row-dark";
				}}
				loading={loading}
				pagination={{
					position: ["bottomRight"],
					defaultPageSize: 50,
					showSizeChanger: false,
					pageSizeOptions: ["10", "20", "50", "100"],
				}}
				onChange={handleTableChange}
			/>
		</>
	);
};

export default UserManagementPage;
