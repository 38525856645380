import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Button,
  Dropdown,
  Space,
  Input,
  Select,
  Pagination,
  message,
  notification,
  DatePicker,
  Tooltip,
  Tag,
  Checkbox,
} from "antd";
import type {
  ColumnsType,
  TablePaginationConfig,
  ColumnType,
} from "antd/es/table";
import type { FilterValue } from "antd/es/table/interface";
import type { MenuProps, DatePickerProps } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  SaveOutlined,
  UploadOutlined,
  SearchOutlined,
  CaretDownOutlined,
  LinkOutlined,
  FileSearchOutlined,
  EditOutlined,
} from "@ant-design/icons";
import IO from "./../lib/socketio";
import Events from "./../lib/events";
import axios from "axios";
import Contents from "./ModalContents";
import { title } from "process";
import dayjs from "dayjs";
import type { RangePickerProps } from "antd/es/date-picker";
import Editorial from "./EditorialModal";
import Preview from "./ModalContents";
import DistributorsModal from "./DistributorsModal";
import DistributorsStatusModal from "./DistributorsStatusModal";
import Delete from "./Delete";
import moment from "moment";

const TableNews = (props: any) => {
  const [fulldata, setFulldata] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [editable, setEditable] = useState(-1);
  const [editablePDate, setEditablePDate] = useState(-1);
  // Filters' state
  const [searchRDate, setSearchRDate] = useState(false);
  const [searchUDate, setSearchUDate] = useState(false);
  const [searchPDate, setSearchPDate] = useState(false);
  const [searchTitle, setSearchTitle] = useState(false);
  const [searchPublisher, setSearchPublisher] = useState(false);
  const [searchStatus, setSearchStatus] = useState(false);
  // Options
  const [allTitle, setAllTitle] = useState([]);
  const [allPublishers, setAllPublishers] = useState([]);
  const [editorialModalOpen, setEditorialModalOpen] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [rowData, setRowData] = useState();

  //option for modal
  const [hasSelecteded, setHasSelecteded] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // distributor status modal
  const [distributorsStatusModal, setDistributorsStatusModal] =
    useState<boolean>(false);
  const [distributionRecord, setDistributionRecord] = useState<any>(null);
  const [RDatevisible, setRDateVisible] = useState(false);
  const [UDatevisible, setUDateVisible] = useState(false);
  const [PDatevisible, setPDateVisible] = useState(false);

  const openRDatePicker = useRef<any>(true);
  const openUDatePicker = useRef<any>(true);
  const openPDatePicker = useRef<any>(true);

  // Date selection sanitization
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [filterState, setFilterState] = useState({
    r_date: "",
    u_date: "",
    p_date: "",
    type: "",
    title: "",
    publisher: "",
    status: [],
  });

  interface DataType {
    key: React.Key;
    receive_date: string;
    upload_date: string;
    publisher: string;
    title: string;
    status: string;
    type: string;
    tags: string;
    url: string;
  }

  interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
  }

  const { TextArea } = Input;

  const formatDate = (date: any) => {
    // var d = new Date(date);
    // return d.toLocaleString();
    if (date !== "No Date") {
      var d = new Date(date);
      var options: any = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        hourCycle: "h12",
      };
      var formatter = new Intl.DateTimeFormat("en-GB", options);
      var formattedDate = formatter.format(d);
      formattedDate = formattedDate.replace("am", "AM").replace("pm", "PM");
      return formattedDate;
    } else {
      return date;
    }
  };

  const handleEditorialConfigModal = (rec: any) => {
    setEditorialModalOpen(true);
    setRowData(rec);
  };

  const handleEditorialCancel = () => {
    setEditorialModalOpen(false);
  };

  const handleReviewConfigModal = (rec: any) => {
    setReviewModalOpen(true);
    setRowData(rec);
  };

  const handleReviewCancel = () => {
    setReviewModalOpen(false);
  };

  // Customizing the header
  const rdateHeader = () => {
    const handleRDateChange = (e: any) => {
      setRDateVisible(false);
      //   setFilterState({
      //     ...filterState,
      //     r_date: e,
      //   });
      if (e === "") {
        setFilterState({
          ...filterState,
          r_date: "No Date",
        });
      } else {
        setFilterState({
          ...filterState,
          r_date: e,
        });
      }

      if (e == "") {
        setSearchRDate(false);
        setData(fulldata);
      }
    };

    const onRDateChange: DatePickerProps["onChange"] = (date, dateString) => {
      console.log(dateString);
      handleRDateChange(dateString);
    };

    return (
      <>
        {!searchRDate ? (
          <Tooltip
            placement="top"
            title={() => {
              return (
                <div style={{ textAlign: "center" }}>
                  Article ingested into database
                </div>
              );
            }}
          >
            <Button
              type="text"
              onClick={() => {
                setSearchRDate(true);
              }}
              style={{ fontWeight: "bold" }}
            >
              <Space>
                Receive Date
                <SearchOutlined />
              </Space>
            </Button>
          </Tooltip>
        ) : (
          <DatePicker
            allowClear
            open={RDatevisible}
            onOpenChange={(open) => setRDateVisible(open)}
            autoFocus={true}
            size={"middle"}
            // onClick={() => openRDatePicker.current == true}
            onBlur={() => {
              setSearchRDate(false);
            }}
            onChange={onRDateChange}
            renderExtraFooter={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  type="link"
                  onClick={() => {
                    handleRDateChange("");
                    // openRDatePicker.current = false;
                  }}
                >
                  No Date
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    handleRDateChange(moment().format("YYYY-MM-DD"));
                    // openRDatePicker.current = false;
                  }}
                >
                  Today
                </Button>
              </div>
            )}
            showToday={false}
          />
        )}
      </>
    );
  };

  const udateHeader = () => {
    const handleUDateChange = (e: any) => {
      setUDateVisible(false);
      if (e === "") {
        setFilterState({
          ...filterState,
          u_date: "No Date",
        });
      } else {
        setFilterState({
          ...filterState,
          u_date: e,
        });
      }

      if (e == "") {
        setSearchRDate(false);
        setData(fulldata);
      }
    };

    const onUDateChange: DatePickerProps["onChange"] = (date, dateString) => {
      console.log(dateString);
      handleUDateChange(dateString);
    };

    return (
      <>
        {!searchUDate ? (
          <Tooltip
            placement="top"
            title={() => {
              return (
                <div style={{ textAlign: "center" }}>
                  Article uploaded to wordpress as draft
                </div>
              );
            }}
          >
            <Button
              type="text"
              onClick={() => {
                setSearchUDate(true);
              }}
              style={{ fontWeight: "bold" }}
            >
              <Space>
                Upload Date
                <SearchOutlined />
              </Space>
            </Button>
          </Tooltip>
        ) : (
          <DatePicker
            allowClear
            open={UDatevisible}
            onOpenChange={(open) => setUDateVisible(open)}
            autoFocus={true}
            size={"middle"}
            onClick={() => openUDatePicker.current == true}
            onBlur={() => {
              setSearchUDate(false);
            }}
            onChange={onUDateChange}
            renderExtraFooter={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  type="link"
                  onClick={() => {
                    handleUDateChange("");
                    openUDatePicker.current = false;
                  }}
                >
                  No Date
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    handleUDateChange(moment().format("YYYY-MM-DD"));
                    openUDatePicker.current = false;
                  }}
                >
                  Today
                </Button>
              </div>
            )}
            showToday={false}
          />
        )}
      </>
    );
  };

  const pdateHeader = () => {
    const handlePDateChange = (e: any) => {
      setPDateVisible(false);
      //   setFilterState({
      //     ...filterState,
      //     p_date: e,
      //   });
      if (e === "") {
        setFilterState({
          ...filterState,
          p_date: "No Date",
        });
      } else {
        setFilterState({
          ...filterState,
          p_date: e,
        });
      }

      if (e == "") {
        setSearchPDate(false);
        setData(fulldata);
      }
    };

    const onPDateChange: DatePickerProps["onChange"] = (date, dateString) => {
      console.log(dateString);
      handlePDateChange(dateString);
    };

    return (
      <>
        {!searchPDate ? (
          <Tooltip
            placement="top"
            title={() => {
              return (
                <div style={{ textAlign: "center" }}>
                  Space Article publication date from publisher
                </div>
              );
            }}
          >
            <Button
              type="text"
              onClick={() => {
                setSearchPDate(true);
              }}
              style={{ fontWeight: "bold" }}
            >
              <Space>
                Published on
                <SearchOutlined />
              </Space>
            </Button>
          </Tooltip>
        ) : (
          <DatePicker
            allowClear
            // open={openPDatePicker.current}
            open={PDatevisible}
            onOpenChange={(open) => setPDateVisible(open)}
            autoFocus={true}
            size={"middle"}
            onClick={() => openPDatePicker.current == true}
            onBlur={() => {
              setSearchPDate(false);
            }}
            onChange={onPDateChange}
            renderExtraFooter={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  type="link"
                  onClick={() => {
                    handlePDateChange("");
                    openPDatePicker.current = false;
                  }}
                >
                  No Date
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    handlePDateChange(moment().format("YYYY-MM-DD"));
                    openPDatePicker.current = false;
                  }}
                >
                  Today
                </Button>
              </div>
            )}
            showToday={false}
          />
        )}
      </>
    );
  };

  const typeHeader = () => {
    const handleTypeChange: MenuProps["onClick"] = (e: any) => {
      setFilterState({
        ...filterState,
        type: e.key,
      });
    };

    const items: MenuProps["items"] = [
      {
        label: "Show All",
        key: "",
        onClick: handleTypeChange,
      },
      {
        label: "RSS",
        key: "rss",
        onClick: handleTypeChange,
      },
      {
        label: "URL",
        key: "url",
        onClick: handleTypeChange,
      },
      {
        label: "Email",
        key: "email",
        onClick: handleTypeChange,
      },
    ];

    return (
      <Dropdown menu={{ items, selectable: true }} trigger={["click"]}>
        <Button
          type="link"
          style={{ color: "rgba(0,0,0,0.85)", fontWeight: "bold" }}
        >
          <Space>
            Type
            <DownOutlined style={{ fontSize: "11px" }} />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  const titleHeader = () => {
    const handleTitleChange = (e: any) => {
      if (e != null) {
        let newData: any = [];
        fulldata.filter((element: any) => {
          console.log(e);
          if (element.title.includes(e)) {
            newData.push(element);
          }
        });
        setData(newData);
      } else {
        setData(fulldata);
      }
    };

    const onTitleChange = (value: string) => {
      console.log(`selected ${value}`);
      handleTitleChange(value);
    };

    const onTitleSearch = (value: string) => {
      console.log("search:", value);
      if (value == "") {
        setSearchTitle(false);
      }
    };

    const onTitleClear = () => {
      setSearchTitle(false);
      console.log(searchTitle);
    };

    const filterTitle = () => {
      setSearchTitle(true);
      // Create select options list
      let titleOptions: any = [];
      fulldata
        .sort((a: any, b: any) => {
          if (a["title"] < b["title"]) return -1;
          if (a["title"] > b["title"]) return 1;
          return 0;
        })
        .map((element: any) =>
          titleOptions.push({
            key: `s-${element.key}`,
            value: element.title,
            label: element.title,
          })
        );
      setAllTitle(titleOptions);
      console.log("titleoption", titleOptions);
    };

    return (
      <>
        {!searchTitle ? (
          <Button
            type="text"
            onClick={filterTitle}
            style={{ fontWeight: "bold" }}
          >
            <Space>
              Story Title
              <SearchOutlined />
            </Space>
          </Button>
        ) : (
          <Select
            placeholder="Filter Story Title"
            showSearch
            allowClear
            onBlur={() => {
              setSearchTitle(false);
            }}
            onChange={onTitleChange}
            onSearch={onTitleSearch}
            onClear={onTitleClear}
            style={{ width: "100%" }}
            options={allTitle}
          />
        )}
      </>
    );
  };

  const publisherHeader = () => {
    const handlePublisherChange = (e: any) => {
      console.log("e", e);
      setFilterState({
        ...filterState,
        publisher: e,
      });
    };

    const onPublisherChange = (value: string) => {
      console.log(`selected ${value}`);
      handlePublisherChange(value);
    };

    const onPublisherSearch = (value: string) => {
      console.log("search:", value);
      if (value == "") {
        setSearchPublisher(false);
      }
    };

    const onPublisherClear = () => {
      setSearchPublisher(false);
      console.log(searchPublisher);
    };

    const filterPublisher = () => {
      setSearchPublisher(true);
      console.log(searchPublisher);
      let publisherOptions: any = [];
      const pdata = fulldata
        .map((element: any) => element.publisher)
        .filter((value, index, self) => self.indexOf(value) === index);
      pdata.sort().map((d: any) => {
        publisherOptions.push({
          key: `p-${d}`,
          value: d,
          label: d,
        });
      });
      setAllPublishers(publisherOptions);
      console.log("puboption", publisherOptions);
    };

    return (
      <>
        {!searchPublisher ? (
          <Button
            type="text"
            onClick={filterPublisher}
            style={{ fontWeight: "bold" }}
          >
            <Space>
              Publishers
              <SearchOutlined />
            </Space>
          </Button>
        ) : (
          <Select
            placeholder="Filter Publisher"
            showSearch
            allowClear
            onBlur={() => setSearchPublisher(false)}
            onChange={onPublisherChange}
            onSearch={onPublisherSearch}
            onClear={onPublisherClear}
            style={{ width: "100%" }}
            options={allPublishers}
          />
        )}
      </>
    );
  };

  // Written by Siew
  // Start
  // Function for close the status dropdown option when clicked outside detected
  const statusRef = useRef<any>();
  useEffect(() => {
    const statusRefFunction = (e: any) => {
      if (statusRef.current != null) {
        let targetElement = e.target;
        while (targetElement.parentNode) {
          targetElement = targetElement.parentNode;
          if (
            targetElement.className === "ant-table-cell" ||
            targetElement.className === "statusRef"
          ) {
            break;
          }
        }

        if (
          targetElement.className !== "ant-btn ant-btn-text" &&
          statusRef.current.className !== targetElement.className
        ) {
          setSearchStatus(false);
          statusRef.current = null;
          document.removeEventListener("click", statusRefFunction);
        }
      }
    };

    if (searchStatus === true) {
      document.addEventListener("click", statusRefFunction);
    }
  }, [statusRef, searchStatus]);
  // End

  // Written by Siew
  // Start
  const statusHeader = () => {
    const handleCheckbox = (value: any) => {
      let checked: any = [];
      const selectedStatus: any = filterState.status;
      const existing = selectedStatus.includes(value);

      if (existing === true) {
        checked = selectedStatus.filter((item: any) => item !== value);
        setFilterState({
          ...filterState,
          status: checked,
        });
      } else {
        checked = filterState.status;
        checked.push(value);
        setFilterState({
          ...filterState,
          status: checked,
        });
      }
    };

    const uniqueStatus: any = [];
    fulldata.map((item: any) => {
      item.status.map((list: any) => {
        if (uniqueStatus.includes(list.toString()) === false) {
          uniqueStatus.push(list);
        }
      });
    });

    const list = uniqueStatus.map((item: any) => {
      let color = "";
      if (item === "DB") {
        color = "geekblue";
      } else if (item === "WP") {
        color = "cyan";
      } else if (item === "DIS") {
        color = "green";
      }
      const selectedtStatus: any = filterState.status;
      return {
        value: item,
        label: (
          <Checkbox
            className="statusRef"
            checked={selectedtStatus.includes(item)}
            style={{ float: "left", width: "100%" }}
            onClick={() => handleCheckbox(item)}
          >
            <Tag
              color={item.includes("!") ? "red" : color}
              className="statusRef"
            >
              {item.toUpperCase()}
            </Tag>
          </Checkbox>
        ),
      };
    });
    // console.log("123= ",statusRef.current)s
    return (
      <>
        {searchStatus === false ? (
          <Button
            type="text"
            onClick={() => setSearchStatus(true)}
            style={{ fontWeight: "bold" }}
          >
            <Space>
              Status
              <SearchOutlined />
            </Space>
          </Button>
        ) : (
          <div className="statusRef" ref={statusRef}>
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              style={{ width: "100%", fontWeight: "normal" }}
              mode="multiple"
              placeholder="Search Status"
              allowClear
              showArrow
              showSearch
              value={[]}
              options={list}
              filterOption={(inputValue: string, option: any) =>
                option.value.toLowerCase().includes(inputValue.toLowerCase())
              }
            />
          </div>
        )}
      </>
    );
  };
  // End

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(7, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });

  const handleDisClick = (record: any) => {
    console.log(`Clicked 'dis' for item with id: ${record.key}`);
    console.log(record);
    setDistributionRecord(record);
    setDistributorsStatusModal(true);
  };

  // Written by Siew
  // Start
  const updatePublishedDate = (value: any, record: any, rowIndex: any) => {
    const d = new Date(value);
    console.log("Pdate:", d);
    updateIntoDatabase(d, record.key, "publish_date");
    let newdata: any = data;
    let row = (currentPage - 1) * 10;
    newdata[rowIndex + row].publish_date = value;
    // message.info("Publish Date Updated!");
    notification.success({
      message: "Publish Date Updated!",
    });
    setEditablePDate(-1);
  };
  // End

  const columns: ColumnsType<DataType> = [
    {
      title: rdateHeader,
      dataIndex: "receive_date",
      sorter: (a: any, b: any) => {
        return a["receive_date"]
          .toString()
          .localeCompare(b["receive_date"].toString());
      },
      //   sorter: (a: any, b: any) => {
      //     return (
      //       new Date(a["receive_date"]).getTime() -
      //       new Date(b["receive_date"]).getTime()
      //     );
      //   },
      render: (value: any) => (value ? formatDate(value) : ""),
      width: "13%",
      align: "center",
    },
    {
      title: udateHeader,
      dataIndex: "upload_date",
      sorter: (a: any, b: any) => {
        return a["upload_date"]
          .toString()
          .localeCompare(b["upload_date"].toString());
      },
      //   sorter: (a: any, b: any) => {
      //     return (
      //       new Date(a["upload_date"]).getTime() -
      //       new Date(b["upload_date"]).getTime()
      //     );
      //   },
      render: (value: any) => (value ? formatDate(value) : <i>No Date</i>),
      width: "13%",
      align: "center",
    },
    {
      title: pdateHeader,
      dataIndex: "publish_date",
      sorter: (a: any, b: any) => {
        return a["publish_date"]
          .toString()
          .localeCompare(b["publish_date"].toString());
      },
      // sorter= {(a:any, b:any) => new Date(a[`${fname}`]).getTime() - new Date(b[`${fname}`]).getTime() },
      render: (value: any, record: any, rowIndex: any) => {
        return editablePDate == rowIndex ? (
          // PDate datepicker
          <DatePicker
            size={"middle"}
            disabledDate={disabledDate}
            // disabledTime={disabledDateTime}
            showTime
            allowClear
            onOk={(value: any) => {
              updatePublishedDate(value, record, rowIndex);
            }}
            onBlur={() => setEditablePDate(-1)}
          />
        ) : (
          <Space align="center" size={0}>
            <Button
              block
              style={{
                color: "#000000",
                whiteSpace: "normal",
              }}
              type="link"
              onClick={() => setEditablePDate(rowIndex)}
            >
              {value ? formatDate(value) : <i>No date</i>}
            </Button>
          </Space>
        );
      },
      width: "13%",
      align: "center",
    },
    {
      title: typeHeader,
      dataIndex: "type",
      width: "7%",
      align: "center",
      render: (type: string, rowIndex: any) => {
        return type.toUpperCase();
      },
    },
    {
      title: titleHeader,
      dataIndex: "title",
      width: "25%",
      render: (text: string, record: any, rowIndex: any) => {
        // return record.status == "Saved to Database" ? (
        return (
          <Space align="center" size={0}>
            <Button
              style={{
                color: "#4A97DE",
                textAlign: "left",
              }}
              onMouseEnter={(e) => (e.currentTarget.style.color = "#BBC6D1")}
              onMouseLeave={(e) => (e.currentTarget.style.color = "#4A97DE")}
              type="text"
              onClick={() => handleEditorialConfigModal(record)}
            >
              <EditOutlined />
            </Button>
            {text}
          </Space>
          // ) : (
          // 	<Space align="center" size={0}>
          // 		<Button
          // 			style={{
          // 				color: "#4A97DE",
          // 				textAlign: "left",
          // 			}}
          // 			onMouseEnter={(e) =>
          // 				(e.currentTarget.style.color = "#BBC6D1")
          // 			}
          // 			onMouseLeave={(e) =>
          // 				(e.currentTarget.style.color = "#4A97DE")
          // 			}
          // 			type="text"
          // 			onClick={() => handleReviewConfigModal(record)}
          // 		>
          // 			<FileSearchOutlined />
          // 		</Button>
          // 		{text}
          // 	</Space>
        );
      },
    },
    {
      title: publisherHeader,
      dataIndex: "publisher",
      width: "15%",
      align: "center",
    },
    {
      title: statusHeader,
      dataIndex: "status",
      width: "20%",
      align: "center",
      render: (status: any, record: any) => (
        <Space size={0}>
          {status.map((s: any) => {
            let color = "black";
            if (s === "DB") {
              color = "geekblue";
              return (
                <Tag
                  color={color}
                  key={s}
                >
                  {s.toUpperCase()}
                </Tag>
              );
            }
            if (s === "WP") {
              color = "cyan";
            }
            if (s === "DIS") {
              color = "green";
            }
            color = s.includes("!") ? "red" : color;
            return (
                <Tag
                  color={color}
                  key={s}
                  onClick={() => handleDisClick(record)}
                  style={{ cursor: "pointer" }}
                >
                  {s.toUpperCase()}
                </Tag>
            );
          })}
        </Space>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    // console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      name: record.status,
    }),
    columnWidth: "1%",
  };
  const hasSelected = selectedRowKeys.length > 0;

  const fetchData = () => {
    // setLoading(true);
    // props.fetchData();
    fetch(`/api/website_news`)
      .then((res) => res.json())
      .then((res) => {
        const newData = res.data.map((req: any) => ({
          key: req._id,
          receive_date: req.receive_date ? req.receive_date : "No Date",
          upload_date: req.upload_date ? req.upload_date : "No Date",
          publish_date: req.publish_date ? req.publish_date : "No Date",
          author: req.author,
          publisher: req.publisher,
          title: req.title,
          status: req.status,
          type: req.type,
          url: req.url,
          tags: req.tags,
          categories: req.categories,
          contents: req.contents,
          images: req.images,
          distribution_status: req.distribution_status,
        }));
        return newData;
      })
      .then((newData) => {
        console.log("latest data fetch", newData);
        setFulldata(newData);
        setData(newData);
        // setLoading(false);
        setSelectedRowKeys([]);
        props.storyPageLoading(false);
      });
  };

  const updateIntoDatabase = (value: any, key: any, field: any) => {
    console.log("new data:", value);
    console.log("id", key);
    console.log(field);
    let stat: any = {};
    stat[field] = value;
    return new Promise((resolve: any, reject: any) => {
      axios
        .put(`/api/website_news/${key}`, stat)
        .then((res: any) => {
          resolve(res.data ? res.data : []);
        })
        .catch((err: any) => {
          console.error(err);
          reject(err);
        });
    });
  };

  const handleFilter = () => {
    console.log("handle filter", filterState);

    if (
      filterState.r_date == "" &&
      filterState.u_date == "" &&
      filterState.p_date == "" &&
      filterState.type == "" &&
      filterState.title == "" &&
      filterState.publisher == "" &&
      filterState.status.length == 0
    ) {
      setData(fulldata);
    } else {
      let newdata;
      newdata = fulldata.filter((element: any) =>
        element.type.includes(filterState.type)
      );
      newdata = newdata.filter((element: any) =>
        element.receive_date.includes(filterState.r_date)
      );
      newdata = newdata.filter((element: any) =>
        element.upload_date.includes(filterState.u_date)
      );
      newdata = newdata.filter((element: any) =>
        element.publish_date.includes(filterState.p_date)
      );
      newdata = newdata.filter((element: any) =>
        element.publisher.includes(filterState.publisher)
      );
      if (filterState.status.length != 0) {
        newdata = newdata.filter((element: any) =>
          filterState.status.every((list: any) => element.status.includes(list))
        );
      }
      setData(newdata);
    }

    setCurrentPage(1);
  };

  useEffect(() => {
    Events.on("updateStoryList", () => {
      fetchData();
    });

    return () => {
      Events.off("updateStoryList", () => {
        fetchData();
      });
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (editorialModalOpen == false) {
      fetchData();
    }
  }, [editorialModalOpen]);

  useEffect(() => {
    handleFilter();
  }, [filterState]);

  const uploadWordpress = () => {
    props.storyPageLoading(true);
    console.log("selected", selectedRowKeys);
    IO.emit("rss-wordpress", {
      userId: sessionStorage.getItem("email"),
      keys: selectedRowKeys,
    });

    notification.info({
      message: "Uploading to Wordpress",
      description: `Articles are being uploaded to Wordpress. Please wait for a while.`,
      duration: 0,
    });

    // setTimeout(() => {
    //   console.log("upload to wordpress");
    //   fetchData();
    //   setSelectedRowKeys([]);
    // }, 5000);
  };

  const deleteItems = () => {
    // setLoading(true);
    console.log("delete:", selectedRowKeys);
    return new Promise((resolve: any, reject: any) => {
      axios
        .post(
          `/api/website_news/deleteMany`,
          selectedRowKeys
        )
        .then((res: any) => {
          console.log(res);
          resolve(res.data ? res.data : []);
          fetchData();
          setSelectedRowKeys([]);

          if (res.data.deletedCount > 0) {
            notification.success({
              message: "Delete Success",
              description: `${res.data.deletedCount} items deleted.`,
            });
          } else {
            notification.error({
              message: "Delete Failed",
              description: "No items deleted.",
            });
          }
        })
        .catch((err: any) => {
          console.error(err);
          reject(err);
          notification.error({
            message: "Delete Failed",
            description: err,
          });
        });
    });
  };

  const resetFilter = () => {
    console.log("filter reset");
    setFilterState({
      r_date: "",
      u_date: "",
      p_date: "",
      type: "",
      title: "",
      publisher: "",
      status: [],
    });
    setSearchRDate(false);
    setSearchUDate(false);
    setSearchPDate(false);
    setSearchTitle(false);
    setSearchPublisher(false);
    setSearchStatus(false);
    setSelectedRowKeys([]);
  };

  const OpenDistributorModal = () => {
    setIsModalVisible(true);
    console.log("selected keys for distribution", selectedRowKeys);
  };

  const clearSelectedKeys = () => {
    setLoading(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
      fetchData();
    }, 4000);
  };

  const CloseDistributorModal = () => {
    setIsModalVisible(false);
    clearSelectedKeys();
  };

  return (
    <>
      {editorialModalOpen && (
        <Editorial
          data={rowData}
          open={editorialModalOpen}
          handleCancel={handleEditorialCancel}
          handleOk={() => setEditorialModalOpen(false)}
        />
      )}
      {reviewModalOpen && (
        <Preview
          data={rowData}
          open={reviewModalOpen}
          handleCancel={handleReviewCancel}
          handleOk={() => setReviewModalOpen(false)}
        />
      )}
      {distributorsStatusModal && (
        <DistributorsStatusModal
          open={distributorsStatusModal}
          onCancel={() => setDistributorsStatusModal(false)}
          articleRecord={distributionRecord}
        />
      )}
      <div
        style={{
          display: "flex",
          marginTop: "36px",
          marginBottom: "18px",
          justifyContent: hasSelected ? "space-between" : "flex-end",
        }}
      >
        <div style={{ display: "flex" }}>
{/*           <Button
            className="button-green"
            onClick={uploadWordpress}
            style={hasSelected ? { display: "block" } : { display: "none" }}
          >
            Upload to Wordpress
          </Button>
 */}
          <Button
            className="button-green"
            onClick={OpenDistributorModal}
            style={hasSelected ? { display: "block" } : { display: "none" }}
          >
            Distribute
          </Button>

          <DistributorsModal
            visible={isModalVisible}
            onClose={CloseDistributorModal}
            articles_id={selectedRowKeys}
          />
        </div>
        <div style={{ display: "flex" }}>
          {/* <Button
						type="primary"
						style={{ display: hasSelected ? "block" : "none" }}
						onClick={deleteItems}
						danger
					>
						Delete
					</Button> */}
          <Delete
            title={"Delete Story"}
            message={
              "Are you sure you want to delete? This action is irreversible and will permanently remove the item."
            }
            selectedRowKeys={selectedRowKeys}
            handleDelete={deleteItems}
          />
          <Button
            className="button-green"
            onClick={resetFilter}
            style={{
              display:
                filterState.r_date == "" &&
                filterState.u_date == "" &&
                filterState.p_date == "" &&
                filterState.type == "" &&
                filterState.title == "" &&
                filterState.publisher == "" &&
                filterState.status.length == 0
                  ? "none"
                  : "block",
              marginLeft: "12px",
            }}
          >
            Reset Filter
          </Button>
        </div>
      </div>
      <Table
        rowClassName={(record: any, index: any) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        rowSelection={rowSelection}
        columns={columns}
        rowKey={(record) => record.key}
        dataSource={data}
        size={"small"}
        bordered={true}
        showSorterTooltip={false}
        loading={loading}
        pagination={{
          total: data.length,
          pageSize: pageSize,
          current: currentPage,
          onChange: (page) => page !== currentPage && setCurrentPage(page),
          onShowSizeChange: (current, size) => {
            setCurrentPage(1);
            setPageSize(size);
          },
        }}
        style={{ margin: "0 0 50px 0" }}
      />
    </>
  );
};

export default TableNews;
