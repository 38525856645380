import React, { useEffect, useState } from "react";
import InputUrl from "../components/InputUrl";
import TableNews from "../components/TableNews";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Button, Result, Spin } from "antd";
import { load } from "cheerio";

const UrlPage = () => {
	const [fulldata, setFulldata] = useState([]);
	const [loading, setLoading] = useState<boolean>(false);

	const fetchData = () => {
		setLoading(true)
		fetch(`/api/website_news`)
			.then((res) => res.json())
			.then((res) => {
				const newData = res.data.map((req: any) => ({
					key: req._id,
					upload_date: req.upload_date,
					publish_date: req.publish_date ? req.publish_date : "",
					author: req.author,
					publisher: req.publisher,
					title: req.title,
					status: req.status,
					type: req.type,
					url: req.url,
					content: req.content,
				}));

				return newData;
			})
			.then((newData) => {
				setFulldata(newData);
				setLoading(false)
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleLoading = (data:any) =>{
		setLoading(data)
	}
	
	return (
		<Spin spinning={loading}>
			<div className="submit-story-container-wrapper">
				<InputUrl fulldata={fulldata} />
			</div>
			<TableNews fulldata={fulldata} storyPageLoading={handleLoading}/>
		</Spin>
	);
};

export default UrlPage;
