import React, { useEffect, useState } from "react";
import { Input, Form, Button, notification } from "antd";
import "../App.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ArrnLogo from "../assets/img/Logo3-removebg-preview.png";

const RegisterPage = (props: any) => {
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const formData = [
		{
			key: "username",
			name: "username",
			rules: [{ required: true, message: "Please input your username!" }],
			validateTrigger: "onBlur",
			component: (
				<div className="input-form">
					<label className="label-form">Username</label>
					<Input placeholder="Username" />
				</div>
			),
		},
		{
			key: "email",
			name: "email",
			rules: [
				{ required: true, message: "Please input your email!" },
				{
					type: "email",
					message: "Please input a valid email!",
				},
			],
			validateTrigger: "onBlur",
			component: (
				<div className="input-form">
					<label className="label-form">Email</label>
					<Input placeholder="Email" />
				</div>
			),
		},
		{
			key: "password",
			name: "password",
			rules: [
				{ required: true, message: "Please input your password!" },
				{ min: 8, message: "Password must be at least 8 characters!" },
				// password must contain at least one uppercase letter, one lowercase letter, one number, and one special character
				{
					validator: (rule: any, value: any) => {
						if (
							!value.match(
								/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/
							)
						) {
							return Promise.reject(
								"Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character!"
							);
						} else {
							return Promise.resolve();
						}
					},
				},
			],
			validateTrigger: "onBlur",
			component: (
				<div className="input-form">
					<label className="label-form">Password</label>
					<Input.Password placeholder="Password" />
				</div>
			),
		},
		{
			key: "confirmPassword",
			name: "confirmPassword",
			rules: [
				{ required: true, message: "Please confirm your password!" },
				{
					// validate the password and confirm password
					validator: (rule: any, value: any) => {
						if (value !== form.getFieldsValue().password) {
							return Promise.reject("Passwords do not match!");
						} else {
							return Promise.resolve();
						}
					},
				},
			],
			validateTrigger: "onBlur",
			component: (
				<div className="input-form">
					<label className="label-form">Confirm Password</label>
					<Input.Password placeholder="Confirm Password" />
				</div>
			),
		},
	];

	// handle form when submit
	const onFinish = (values: any) => {
		console.log("Received values of form: ", values);
		// onSubmit(values);
		return new Promise((resolve: any, reject: any) => {
			axios
				.post(
					`/api/users/register`,
					values
				)
				.then((res: any) => {
					console.log(res);

					if (res.data.success) {
						notification.success({
							message: "User Added! Please verify your email!",
							description:
								"Check your email to verify your account with the 6-digit code and the link given.",
						});
						navigate(`/verify?id=${res.data.data.randomCode}`);
					} else {
						notification.error({
							message: "Fail to Add User!",
							description: res.data.message,
						});
					}
					resolve(res);
				})
				.catch((err: any) => {
					console.log(err);
					reject(err);
				});
		});
	};

	// handle fail submit form
	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};

	// handle the reset button
	const onReset = () => {
		form.resetFields();
	};

	const handleReturnLogin = () => {
		navigate("/login");
	};

	return (
		<div className="register-page background-image">
			<div className="logo">
				<img src={ArrnLogo} alt="logo" width={"500vw"}></img>
			</div>
			<div className="form-box">
				<h1>Register</h1>
				<hr style={{ margin: "10px 0px 30px 0px" }} />
				<Form
					form={form}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					{/* loop form to display items */}
					{formData.map((item: any) => (
						<Form.Item {...item}>{item.component}</Form.Item>
					))}

					{/* create the submit and reset button */}
					{/* <Form.Item
						style={{
							display: "flex",
							justifyContent: "flex-end",
							marginBottom: "0px",
							width: "100%",
						}}
					> */}
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<Button
							type="link"
							style={{ margin: "5px" }}
							onClick={onReset}
						>
							Reset
						</Button>
						<div>
							<Button type="link" onClick={handleReturnLogin}>
								Back to Login Page
							</Button>
							<Button
								className="button-green"
								htmlType="submit"
								style={{ margin: "5px" }}
							>
								Submit
							</Button>
						</div>
					</div>
					{/* </Form.Item> */}
				</Form>
			</div>
		</div>
	);
};

export default RegisterPage;
