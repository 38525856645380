import React, { Component, useEffect, useState } from "react";
import "../App.css";
import ArrnLogo from "../assets/img/Logo3-removebg-preview.png";
import {
	ExclamationCircleOutlined,
	LogoutOutlined,
	SettingOutlined,
} from "@ant-design/icons";
import { Button, Space, Menu, Layout, Modal } from "antd";
import {
	Routes,
	Route,
	redirect,
	useNavigate,
	Navigate,
	BrowserRouter,
	NavLink,
} from "react-router-dom";
import MenuComponent from "./MenuComponent";

const Header = (props: any) => {
	const [showModal, setShowModal] = useState(false);

	const items = [
		{
			label: <NavLink to={"/story"}>Story Lists</NavLink>,
			key: "story",
		},
		{
			label: <NavLink to={"/newswire"}>Newswire</NavLink>,
			key: "newswire",
		},
		{
			label: <NavLink to={"/config"}>Publisher Configuration</NavLink>,
			key: "config",
		},
		{
			label: <NavLink to={"/users"}>User Management</NavLink>,
			key: "users",
			role: "admin",
		},
	];

	const confirmLogout = () => {
		const { confirm } = Modal;
		// setShowModal(true);
		confirm({
			title: "Are you sure you want to log out?",
			icon: <ExclamationCircleOutlined />,
			okText: "Confirm",
			onOk() {
				props.handleLogout();
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const handleOk = () => {
		// setShowModal(false);
		props.handleLogout();
	};

	return (
		<>
			{showModal && (
				<Modal
					open={showModal}
					onOk={handleOk}
					onCancel={() => setShowModal(false)}
					okText="Confirm"
				>
					<p>Are you sure you want to log out?</p>
				</Modal>
			)}
			<div style={{ marginLeft: "15px", marginRight: "15px" }}>
				<img src={ArrnLogo} alt="logo" width={"250vw"}></img>
			</div>
			<div style={{ flexGrow: 1 }}>
				<MenuComponent items={items} />
			</div>
			<Button
				type="text"
				icon={<LogoutOutlined />}
				onClick={confirmLogout}
			>
				Log Out
			</Button>
		</>
	);
};

export default Header;
