import { Modal, Button } from "antd";

const Delete = (props: any) => {
	const confirmDelete = () => {
		Modal.confirm({
			title: props.title,
			content: props.message,
			okText: "Delete",
			onOk() {
				props.handleDelete();
			},
		});
	};

	return (
		<Button
			type="primary"
			style={{
				display: props.selectedRowKeys.length !== 0 ? "block" : "none",
			}}
			onClick={confirmDelete}
			danger
		>
			Delete
		</Button>
	);
};

export default Delete;
