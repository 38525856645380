import React, { useEffect, useState } from "react";
import "./App.css";
import LoginPage from "./page/LoginPage";
import RegisterPage from "./page/RegisterPage";
import ForgetPasswordPage from "./page/ForgetPasswordPage";
import ResetPasswordPage from "./page/ResetPasswordPage";
import UserManagementPage from "./page/UserManagementPage";
import UnauthorizedPage from "./page/UnauthorizedPage";
import NotFoundPage from "./page/NotFoundPage";
import TestPage from "./page/TestPage";
import TestSocketIO from "./page/TestSocketIO";
import ConfigPage from "./page/ConfigPage";
import StoryPage from "./page/StoryPage";
import VerifyPage from "./page/VerifyPage";
import EventEmitter from "./lib/events";
import {
	Routes,
	Route,
	redirect,
	useNavigate,
	Navigate,
	BrowserRouter,
} from "react-router-dom";
import IO from "./lib/socketio";
import { Button, Layout, message, notification, List } from "antd";
import LayoutComponent from "./components/Layout";
import NewswirePage from "./page/NewswirePage";
import Notifications from "./components/Notifications";

function App() {
	const navigate = useNavigate();

	const [isLogin, setIsLogin] = useState(false);
	const [isLogout, setIsLogout] = useState(false);
	const [lastActive, setLastActive] = useState(Date.now());

	// antd notification config
	notification.config({
		placement: "bottomRight",
		bottom: 50,
	});

	// Event listeners
	useEffect(() => {
		// listen to distribute_articles event
		EventEmitter.on("distribute_articles", (data: any) => {
			IO.emit("distribute_articles", data);
			console.log("distribute_articles event", data);
		});

		EventEmitter.on("FETCH_NEWSWIRE", () => {
			const data = {
				userId: sessionStorage.getItem("email"),
			};
			IO.emit("FETCH_NEWSWIRE", data);
			console.log("FETCH_NEWSWIRE event", data);
		});

		return () => {
			EventEmitter.off("distribute_articles", () => {});
			EventEmitter.off("FETCH_NEWSWIRE", () => {});
		};
	}, []);

	useEffect(() => {
		IO.on("connect", () => {
			console.log(IO.id);
			IO.emit("RESTART_SERVER", {
				socketId: IO.id,
				userId: sessionStorage.getItem("email"),
			});
		});

		IO.on("connect_error", (err) => {
			console.log(`ARR::connect_error due to ${err.message}`);
		});
	}, []);

	// handle user activity
	// logout user if windows location is not login page or register page when user haven't login
	useEffect(() => {
		const currentwindow = window.location.pathname;
		const NonRestricted = [
			"/login",
			"/register",
			"/verify",
			"/forgetPassword",
			"/resetPassword",
			"/unauthorized",
		];
		if (!sessionStorage.getItem("isLogin")) {
			if (!NonRestricted.includes(currentwindow)) {
				handleLogout();
			}
		}
	}, []);

	useEffect(() => {
		// console.log("Window:", window.location.pathname);
		if (window.location.pathname == "/login") {
			if (sessionStorage.getItem("isLogin")) {
				sessionStorage.removeItem("isLogin");
				window.location.reload();
			}
		}
		if (window.location.pathname == "/story") {
			sessionStorage.setItem("page", "story");
		}
		if (window.location.pathname == "/config") {
			sessionStorage.setItem("page", "config");
		}
	}, []);

	// Reset the session everytime browser was closed or route back to login
	// useEffect(() => {
	// 	window.addEventListener("beforeunload", handleUserLeaving);
	// 	return () => {
	// 		window.removeEventListener("beforeunload", handleUserLeaving);
	// 	};
	// }, []);

	useEffect(() => {
		setLastActive(Date.now());
	}, [isLogin]);

	// Monitor the idle time and automatically log user out if user idles for a set amt of time
	useEffect(() => {
		// console.log("last active", lastActive);
		if (sessionStorage.getItem("isLogin")) {
			const timeout = setTimeout(() => {
				handleLogout();
				notification.info({ message: "You have been logged out." });
				console.log("logout now");
			}, 1800000); // logout after 30min/1800000ms

			return () => clearTimeout(timeout);
		}
	}, [lastActive]);

	// Monitor the user activity and reset the idle time
	useEffect(() => {
		if (sessionStorage.getItem("isLogin")) {
			document.addEventListener("mousemove", handleUserActivity);
			document.addEventListener("keypress", handleUserActivity);

			return () => {
				document.removeEventListener("mousemove", handleUserActivity);
				document.removeEventListener("keypress", handleUserActivity);
			};
		}
	}, []);

	// emit login event to server when reload page
	useEffect(() => {
		if (sessionStorage.getItem("isLogin")) {
			handleSocket();
			console.log("set the user to socket");
		}
	}, []);

	// event listener to notify user
	useEffect(() => {
		// notifications
		IO.on(`NOTIFICATIONS`, (data: any) => {
			console.log("notifications", data);
			if (sessionStorage.getItem("isLogin")) {
				Notifications.show(
					"success",
					data.message,
					data.description ? data.description : null
				);
			}
			EventEmitter.emit("RELOAD_NEWSWIRE_PAGE");
		});

		// upload wordpress notification
		IO.on(`upload_status`, (data: any) => {
			console.log("upload status", data);
			if (sessionStorage.getItem("isLogin")) {
				if (data.status === "success") {
					notification.destroy();
					notification.success({
						message: "Upload successful.",
						description:
							"Your article has been uploaded to Wordpress.",
					});
					EventEmitter.emit("updateStoryList");
				} else if (data.status === "error") {
					notification.destroy();
					notification.error({
						message: "Failed to upload article to Wordpress.",
						description: data.message,
					});
					EventEmitter.emit("updateStoryList");
				}
			}
		});

		// Articles already exists in wordpress
		IO.on(`article_exists`, (data: any) => {
			// notification.destroy(); will destroy all existing notification such as wait for a while notification
			notification.destroy();
			console.log("article exists", data);
			let existing_articles = data.articles;
			const description = (
				<ul>
					{existing_articles.map((article: any, index: any) => (
						<li key={index}>{article}</li>
					))}
				</ul>
			);
			if (sessionStorage.getItem("isLogin")) {
				notification.info({
					message:
						"The following articles already exist in Wordpress.",
					description: description,
				});
				EventEmitter.emit("updateStoryList");
			}
		});

		IO.on(`url_status`, (data: any) => {
			console.log("get url status notification");
			console.log("url status", data);
			if (sessionStorage.getItem("isLogin")) {
				if (data.status === "success") {
					notification.success({
						message: "Data saved!",
						description: "Your article has been saved in database.",
					});
					// emit events to update the story list
					EventEmitter.emit("updateStoryList");
				} else if (data.status === "info") {
					notification.info({
						message: "Data exists!",
						description: data.message,
						duration: 0,
					});
				} else if (data.status === "error") {
					notification.error({
						message: "Failed to save data!",
						description: data.message,
						duration: 0,
						btn: (
							<Button onClick={() => openWindow(data.url)} danger>
								Check URL
							</Button>
						),
					});
				}
			}
		});

		// Upload document status
		IO.on(`document_status`, (data: any) => {
			console.log("get document status notification");
			console.log("document status", data);
			if (sessionStorage.getItem("isLogin")) {
				if (data.status === "success") {
					notification.success({
						message: "Data saved!",
						description: "Your article has been saved in database.",
					});
					// emit events to update the story list
					EventEmitter.emit("updateStoryList");
				} else if (data.status === "info") {
					notification.info({
						message: "Data exists!",
						description: (
							<List
								style={{
									maxHeight: "400px",
									overflowY: "auto",
									overflowX: "auto",
								}}
								dataSource={data.file_name}
								renderItem={(item, index) => (
									<List.Item>{`${item} upload failed`}</List.Item>
								)}
							/>
						),
					});
				} else if (data.status === "error") {
					notification.error({
						message: data.message,
						description: (
							<List
								style={{
									maxHeight: "400px",
									overflowY: "auto",
									overflowX: "auto",
								}}
								dataSource={data.file_name}
								renderItem={(item, index) => (
									<List.Item>{`${item} save failed`}</List.Item>
								)}
							/>
						),
					});
				}
			}
		});

		return () => {
			IO.off(`upload_status`);
			IO.off(`url_status`);
			IO.off(`document_status`);
		};
	}, []);

	const openWindow = (url: any) => {
		window.open(url, "newwindow", "width=600,height=400");
		return false;
	};

	const handleUserActivity = () => {
		setLastActive(Date.now());
	};

	// assign the user to which socket room
	const handleSocket = () => {
		if (sessionStorage.getItem("isLogin")) {
			IO.emit("login", sessionStorage.getItem("email"));
		}
	};

	const canLogin = (data: any, status: any) => {
		if (status == true) {
			sessionStorage.setItem("isLogin", "true");

			// create a session for each elements in data
			for (let key in data) {
				sessionStorage.setItem(key, data[key]);
			}
			console.log("data", data);

			// create a socket connection on login and send persistance data to server
			handleSocket();

			navigate("/story", { replace: true });
			notification.info({
				message: "Login successful.",
				description: "Welcome to ARR News!",
				placement: "topRight",
				duration: 3,
				top: 50,
			});
			// <Navigate replace to="/company_search" />;
		}
	};

	const handleLogout = async () => {
		await IO.emit("logout", sessionStorage.getItem("email"));
		// remove all session in sessionStorage
		sessionStorage.clear();
		setIsLogin(false);
		// emit logout event to server
		await navigate("/login", { replace: true });
	};

	return (
		<Routes>
			<Route
				path=""
				element={
					sessionStorage.getItem("isLogin") ? (
						<Navigate to="/story" />
					) : (
						<Navigate to="/login" />
					)
				}
			/>
			<Route path="login" element={<LoginPage canLogin={canLogin} />} />
			<Route path="register" element={<RegisterPage />} />
			<Route path="forgetPassword" element={<ForgetPasswordPage />} />
			<Route path="resetPassword" element={<ResetPasswordPage />} />
			<Route path="verify" element={<VerifyPage />} />
			<Route path="unauthorized" element={<UnauthorizedPage />} />
			<Route path="*" element={<NotFoundPage />} />
			{/* <Route path="/socket" element={<TestSocketIO />} /> */}

			{/* Pages that need Header and Footer */}
			<Route
				path="story"
				element={
					<LayoutComponent handleLogout={handleLogout}>
						<StoryPage />
					</LayoutComponent>
				}
			/>
			<Route
				path="newswire"
				element={
					<LayoutComponent handleLogout={handleLogout}>
						<NewswirePage />
					</LayoutComponent>
				}
			/>
			<Route
				path="config"
				element={
					<LayoutComponent handleLogout={handleLogout}>
						<ConfigPage />
					</LayoutComponent>
				}
			/>
			<Route
				path="users"
				element={
					<LayoutComponent handleLogout={handleLogout}>
						<UserManagementPage />
					</LayoutComponent>
				}
			/>
		</Routes>
	);
}

export default App;
