import { Form, Input, Button, Space, notification, Tooltip } from "antd";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";
import "../App.css";
import ArrnLogo from "../assets/img/Logo3-removebg-preview.png";

const VerifyPage = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const items = [
		{
			key: "verificationCode",
			name: "verificationCode",
			rules: [
				{
					required: true,
					message: "Please input your verification code!",
				},
				{
					min: 6,
					message: "Verification code must be 6 digits!",
				},
			],
			validateTrigger: "onBlur",
			component: (
				<Input
					placeholder="Please Enter Verification Code"
					maxLength={6}
					style={{
						width: "80%",
						textAlign: "center",
					}}
				/>
			),
		},
	];

	const onFinish = (values: any) => {
		console.log("Verify code:", values);
		// axios.get(`${process.env.REACT_APP_API_URL}/verify`, )
		return new Promise((resolve: any, reject: any) => {
			axios
				.put(`/api/users/verify`, {
					verificationCode: values.verificationCode,
					// randomCode: "ddb68804-0a93-4d94-a88c-3c0d5e19c6b2",
					randomCode: searchParams.get("id"),
				})
				.then((res: any) => {
					console.log(res);
					if (res.data.success) {
						notification.success({
							message: "Success",
							description:
								res.data.message || "Verification success!",
						});
						navigate("/login");
					} else {
						notification.error({
							message: "Incorrect Code!",
							description: "Please enter the correct code!",
						});
					}
				})
				.catch((err: any) => {
					notification.error({
						message: "Error",
						description: "Something went wrong. Please try again!",
					});
				});
		});
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
		notification.error({
			message: "Error",
			description: "Fail to submit! Please try again!",
		});
	};

	// resend link to email
	const handleResendLink = () => {
		const id = searchParams.get("id");
		return new Promise((resolve: any, reject: any) => {
			axios
				.put(`/api/users/resendCode`, {
					id: id,
				})
				.then((res: any) => {
					console.log(res);
					notification.success({
						message: "Verification code has been sent!",
						description: "Please check your email!",
					});
					resolve(res);
				})
				.catch((err: any) => {
					console.log(err);
					notification.error({
						message: "Fail to resend verification code!",
						description: "Something went wrong. Please try again!",
					});
					reject(err);
				});
		});
	};

	return (
		<div className="verify-page background-image">
			<div className="logo">
				<img src={ArrnLogo} alt="logo" width={"500vw"}></img>
			</div>
			<div className="form-box">
				<h1>Verification</h1>
				<hr style={{ margin: "10px 0px 30px 0px" }} />
				<Form
					form={form}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<div>
						<p>
							A 6-digit PIN has been sent to your email, please
							enter the code below for verification purposes.
						</p>
					</div>
					{items.map((item: any) => (
						<Form.Item {...item}>{item.component}</Form.Item>
					))}
					<Form.Item>
						<Button
							type="primary"
							className="button-green"
							htmlType="submit"
						>
							Send Code
						</Button>
					</Form.Item>
					<hr />
					<div className="button-container">
						<Button
							type="link"
							className="button-link"
							onClick={() => navigate("/login")}
						>
							Back to Login Page
						</Button>
						<Tooltip
							title="Resend verification code to your email."
							placement="right"
						>
							<Button
								type="link"
								className="button-link"
								onClick={handleResendLink}
							>
								Resend Code
							</Button>
						</Tooltip>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default VerifyPage;
