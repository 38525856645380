import { Modal } from "antd";

const ModalComponent = (props: any) => {
	return (
		<Modal
			title={props.title}
			open={props.open}
			onOk={props.handleOk}
			onCancel={props.handleCancel}
			okText={props?.okText || "OK"}
			cancelText={props?.cancelText || "Cancel"}
		>
			{props.children}
		</Modal>
	);
};

export default ModalComponent;
