import { useState, useEffect } from "react";
import {
	Button,
	Modal,
	Input,
	Checkbox,
	notification,
	List,
	Spin,
	message,
} from "antd";
import axios from "axios";
import isEqual from "lodash/isEqual";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Buffer } from "buffer";
import { v4 as uuidv4 } from "uuid";

function ManageModal(props: any) {
	const [dataModalOpen, setDataModalOpen] = useState<boolean>(false);
	const [allData, setAllData] = useState<any>([]);
	const [currentData, setCurrentData] = useState<any>([]);
	const [tempAllData, setTempAllData] = useState<any>("");
	const [selectedRemoveData, setSelectedRemoveData] = useState<number[]>([]);
	const [newDataInputValue, setNewDataInputValue] = useState<string>("");
	const [confirmRemoveModal, setConfirmRemoveModal] =
		useState<boolean>(false);
	const [modalLoading, setModalLoading] = useState<boolean>(false);

	// Wordpress header and proxies
	const credential = `${process.env.REACT_APP_WORDPRESS_API_USERNAME}:${process.env.REACT_APP_WORDPRESS_API_PASSWORD}`;
	const token = Buffer.from(credential).toString("base64");
	const header = {
		Authorization: `Basic ${token}`,
	};
	// cmFqYW5nOjhNelogaXJFZSBMUUhPIEFJaHYgZnVYWSBTRnBV
	const proxies = {
		http: `${process.env.REACT_APP_WORDPRESS_API_PROXIES}`,
		https: `${process.env.REACT_APP_WORDPRESS_API_PROXIES}`,
	};

	// Notification config
	notification.config({
		placement: "bottomRight",
		bottom: 50,
		duration: 3,
	});
	useEffect(() => {
		setDataModalOpen(props.open);
		setCurrentData(props.new_data);
		setAllData(props.all_data);
		setTempAllData(props.all_data); // Store the original data to compare with changed data
	}, []);

	// ************************************************************************************************ //
	// Remove data field
	// ************************************************************************************************ //
	// Handle storing the checked check box data
	const handleSelectedRemoveData = (value: number) => {
		if (selectedRemoveData.includes(value)) {
			setSelectedRemoveData(
				selectedRemoveData.filter((data: any) => data !== value)
			);
		} else {
			setSelectedRemoveData([...selectedRemoveData, value]);
		}
	};

	// Handle delete checked checkbox data
	const handleDataRemove = () => {
		setConfirmRemoveModal(false);
		let allDataSaved: any = true;

		//Remove data from to website news database
		const update_website_news_database = (data: any, name: string) => {
			return new Promise(async (resolve: any, reject: any) => {
				// Formating the data before pass to server api
				let newData = { type: name, referenceId: data };
				await axios
					.post(
						`/api/website_news/remove_data`,
						newData
					)
					.then((res: any) => {
						allDataSaved = true;
						resolve(res.data ? res.data : []);
					})
					.catch((err: any) => {
						allDataSaved = false;
						console.error(err);
						reject(err);
					});
			});
		};

		//Remove data from to all tags / categories database
		const update_data_database = (data: any, name: string) => {
			return new Promise(async (resolve: any, reject: any) => {
				// Formating the data before pass to server api
				let newData = { _id: data };
				const type = name === "tags" ? "all_tags" : "all_categories";

				await axios
					.post(
						`/api/${type}/remove_data`,
						newData
					)
					.then((res: any) => {
						update_website_news_database(
							selectedRemoveData,
							props.type
						);
						resolve(res.data ? res.data : []);
					})
					.catch((err: any) => {
						allDataSaved = false;
						console.error(err);
						reject(err);
					});
			});
		};
		update_data_database(selectedRemoveData, props.type);

		// Alert message
		let title = "";
		if (props.type === "categories") {
			if (selectedRemoveData.length > 1) {
				title = props.type;
			} else {
				title = "category";
			}
		}
		if (props.type === "tags") {
			if (selectedRemoveData.length > 1) {
				title = props.type;
			} else {
				title = "tag";
			}
		}
		if (allDataSaved === true) {
			notification.destroy();
			notification.success({
				message: `${
					title.charAt(0).toUpperCase() + title.slice(1)
				} deleted`,
				description: `${selectedRemoveData.length} ${title} deleted successfully from ${props.type} list`,
			});
		} else {
			notification.destroy();
			notification.error({
				message: `Error delete ${title}`,
				description: `${selectedRemoveData.length} ${title} fail to delete from ${props.type} list`,
			});
		}
		// Update data set variable
		let newData1 = currentData;
		for (let x = 0; x < selectedRemoveData.length; x++) {
			newData1 = newData1.filter(
				(item: any) => item !== selectedRemoveData[x]
			);
		}
		setCurrentData(newData1);

		// Update all data set variables
		let newData2 = allData;
		for (let x = 0; x < selectedRemoveData.length; x++) {
			newData2 = newData2.filter(
				(item: any) => item.value !== selectedRemoveData[x]
			);
		}
		setAllData(newData2);
		setSelectedRemoveData([]);
	};

	// ************************************************************************************************ //
	// Edit data field
	// ************************************************************************************************ //
	// Handle edit data
	const handleEditData = (input: any, referenceId: any) => {
		// Update all data set variable
		const updatedData = allData.map((item: any) => {
			if (item.value === referenceId) {
				// Replace the item with the updated data
				return {
					...item,
					value: referenceId,
					label: input.target.value,
					id: item.id,
					disable: item.disable,
				};
			}
			return item;
		});

		// Save the updatedData to the allData variable
		setAllData(updatedData);
	};

	// ************************************************************************************************ //
	// Add new data field
	// ************************************************************************************************ //
	// Handle store add data value before press save or 'Enter'
	const handleNewDataInput = (data: any) => {
		setNewDataInputValue(data.target.value);
	};
	// Handle add data
	const handleNewDataInputConfirm = () => {
		const existingData = allData.filter(
			(item: any) => item.label === newDataInputValue
		);

		if (/^\s*$/.test(newDataInputValue) === true) {
			notification.destroy();
			notification.warning({
				message: "Empty Input!",
				description: `Cannot add empty data`,
			});
		} else if (existingData.length !== 0) {
			notification.destroy();
			notification.info({
				message: "Existing data!",
				description: `"${newDataInputValue}" already in the data list`,
			});
		} else {
			// Add new data to tags/categories
			const update_data_database = async (data: any, name: string) => {
				return new Promise(async (resolve: any, reject: any) => {
					const type =
						name === "tags" ? "all_tags" : "all_categories";

					// Add data
					await axios
						.post(
							`/api/${type}/add_data`,
							data
						)
						.then((res: any) => {
							resolve(res.data ? res.data : []);
							console.log(`${type} added`, res.data);
							notification.success({
								message: `${
									name === "categories" ? "Category" : "Tag"
								} added`,
								description: (
									<span>
										<strong>"{data.name}"</strong> added
										successfully to {name} list
									</span>
								),
							});
							const updatedData = [
								{
									value: res.data._id,
									label: res.data.name,
									id: res.data.id,
								},
								...allData,
							];
							// Sort the updatedData array
							updatedData.sort((a: any, b: any) => {
								return a.label.localeCompare(b.label);
							});
							setAllData(updatedData);
							setCurrentData([res.data._id, ...currentData]);
							setModalLoading(false);
						})
						.catch((err: any) => {
							notification.error({
								message: `Fail to add new ${
									name === "categories" ? "category" : "tag"
								}`,
								description: (
									<span>
										<strong>"{data.name}"</strong> fail add
										to {name} list
									</span>
								),
							});
							console.error(err);
							reject(err);
							setModalLoading(false);
						});
				});
			};
			// Update By Irham: To add new data to database by using uuid instead of matching with the wordpress id
			const get_last_id = (data: any) => {
				let last_id = 0;
				data.forEach((item: any) => {
					if (item.id > last_id) {
						last_id = item.id;
					}
				});
				return last_id;
			};
			const id = get_last_id(allData) + 1;
			const newData = { id: id, name: newDataInputValue };
			update_data_database(newData, props.type);

			// Add new data to wordpress
			const update_wordpress_database = async (
				data: any,
				name: string
			) => {
				setModalLoading(true);
				const newWordpressData = {
					description: "",
					name: data,
					slug: data,
					meta: [],
				};

				const config: any = {
					url: `${process.env.REACT_APP_WORDPRESS_API_LINK}/${name}`,
					method: "POST",
					headers: header,
					proxies: proxies,
					data: newWordpressData,
				};

				await axios(config)
					.then((response) => {
						// Handle the API response here
						console.log("Success= ", response.data);
						// notification.destroy();
						// notification.success({
						// 	message:`${name==='categories'?'Category':'Tag'} added`,
						// 	description:(
						// 		<span>
						// 		  <strong>"{data}"</strong> added successfully to wordpress {name} list
						// 		</span>
						// 	),
						// 	duration: 3000
						// })
						const newDataId = response.data["id"];
						const newData = { id: newDataId, name: data };
						update_data_database(newData, props.type);
					})
					.catch((error) => {
						// Handle any errors due to duplicate data
						if (error.response.data.code === "term_exists") {
							const newDataId =
								error.response.data.data["term_id"];
							const newData = { id: newDataId, name: data };
							update_data_database(newData, props.type);
						} else {
							console.error("Error= ", error);
							notification.destroy();
							notification.error({
								message: `Fail to add new ${
									name === "categories" ? "category" : "tag"
								}`,
								description: (
									<span>
										<strong>"{data}"</strong> fail add to{" "}
										{name} list
									</span>
								),
							});
							setModalLoading(false);
						}
					});
			};
			// This method is closed due to client request
			// update_wordpress_database(newDataInputValue,props.type)
			setNewDataInputValue("");
		}
	};

	// ************************************************************************************************ //
	// Close modal
	// ************************************************************************************************ //
	// Handle modal close and update the changes to database
	const handle_data_config_modal = () => {
		if (isEqual(allData, tempAllData) === false) {
			// Check label empty input
			const emptyField = allData.filter(
				(item: any) => /^\s*$/.test(item.label) == true
			);
			const checkDuplicateData: any = [];
			const duplicateLabels: any = [];

			// Check if the current label already exists in the checkDuplicateData array
			allData.forEach((item: any, index: any, array: any) => {
				const isDuplicate = checkDuplicateData.some(
					(elem: any) => elem.label === item.label
				);
				if (isDuplicate) {
					console.log("Duplicate label found:", item.label);
					duplicateLabels.push(item.label);
				} else {
					checkDuplicateData.push(item);
				}
			});
			const hasDuplicates = duplicateLabels.length > 0;
			if (hasDuplicates) {
				notification.destroy();
				notification.info({
					message:
						"The list have duplicate data so cannot save the changes",
					description: (
						<List
							style={{
								maxHeight: "400px",
								overflowY: "auto",
								overflowX: "auto",
							}}
							dataSource={duplicateLabels}
							renderItem={(item, index) => (
								<List.Item>{`${item}`}</List.Item>
							)}
						/>
					),
				});
				return;
			} else if (emptyField.length !== 0) {
				notification.destroy();
				notification.warning({
					message: "Cannot Perform Saving",
					description: "Cannot accept empty data name",
				});
				return;
			} else {
				// Check the edited data
				let editedDataObjectId: any = [];
				let editedDataName: any = [];
				let editedDataId: any = [];
				let editedDataDisable: any = [];
				let allDataSaved: any = true;

				for (let x = 0; x < allData.length; x++) {
					for (let y = 0; y < tempAllData.length; y++) {
						if (allData[x].value === tempAllData[y].value) {
							if (allData[x].label !== tempAllData[y].label) {
								editedDataObjectId.push(allData[x].value);
								editedDataName.push(allData[x].label);
								editedDataId.push(allData[x].id);
								editedDataDisable.push(allData[x].disable);
							}
						}
					}
				}
				// Update the edit data to all tags / categories database
				const update_data_database = (data: any, name: any) => {
					return new Promise((resolve: any, reject: any) => {
						const type =
							name === "tags" ? "all_tags" : "all_categories";
						axios
							.put(
								`/api/${type}/edit_data`,
								data
							)
							.then((res: any) => {
								resolve(res.data ? res.data : []);
								update_website_news_database(
									{
										referenceId: editedDataObjectId,
										name: editedDataName,
										id: editedDataId,
									},
									props.type
								);
							})
							.catch((err: any) => {
								allDataSaved = false;
								console.error(err);
								reject(err);
							});
					});
				};
				update_data_database(
					{
						referenceId: editedDataObjectId,
						name: editedDataName,
						disable: editedDataDisable,
					},
					props.type
				);

				// Update the edit data to website news database
				const update_website_news_database = (data: any, name: any) => {
					return new Promise((resolve: any, reject: any) => {
						axios
							.post(
								`/api/website_news/edit_data`,
								{ data: data, type: name }
							)
							.then((res: any) => {
								allDataSaved = true;
								resolve(res.data ? res.data : []);
							})
							.catch((err: any) => {
								allDataSaved = false;
								console.error(err);
								reject(err);
							});
					});
				};

				// Alert message
				if (allDataSaved === true) {
					notification.destroy();
					notification.success({
						message: `Edited ${props.type} saved!`,
						description: `Changes has been udpated to ${props.type} list.`,
					});
				} else {
					notification.destroy();
					notification.error({
						message: `Save edited ${props.type} fail!`,
						description: `Error update changes to ${props.type} list.`,
					});
				}
			}
		}

		setNewDataInputValue("");
		setSelectedRemoveData([]);
		// Sort the allData array
		allData.sort((a: any, b: any) => {
			// Replace "propertyToSortBy" with the actual property you want to sort by
			return a.label.localeCompare(b.label);
		});
		props.set_all_data(allData);
		props.set_new_data(currentData);
		setDataModalOpen(false);
		if (props.handleCancel && typeof props.handleCancel === "function") {
			props.handleCancel();
		}
	};

	const notificationCloseModal = (e: any) => {
		message.destroy();
		message.info(
			<>
				<p style={{ fontWeight: "bold" }}>
					Cannot close {props.type} configuration
				</p>
				<p>
					Please wait a while for adding new{" "}
					{props.type === "categories" ? "category" : "tag"}
				</p>
			</>
		);
		e.target.focus();
	};

	return (
		<>
			{confirmRemoveModal && (
				<Modal
					width={"30%"}
					title="Delete Category"
					open={confirmRemoveModal}
					onOk={() => setConfirmRemoveModal(false)}
					onCancel={() => setConfirmRemoveModal(false)}
					className="rounded-modal headermissing"
					footer={[
						<Button
							style={{ float: "left" }}
							onClick={() => setConfirmRemoveModal(false)}
						>
							Cancel
						</Button>,
						<Button
							className="custom-button"
							type="primary"
							danger
							onClick={() => handleDataRemove()}
						>
							Delete
						</Button>,
					]}
				>
					<div
						className="modal-content"
						style={{ textAlign: "center" }}
					>
						<div style={{ fontWeight: "bold", fontSize: "16px" }}>
							Delete Category
						</div>
						<hr />
						<div
							style={{
								lineHeight: "1",
								padding: "24px 10px 0px 10px",
								fontSize: "14px",
							}}
						>
							<p>Are you sure want to delete these categories?</p>
							<p className="important-note">
								<span style={{ fontWeight: "600" }}>
									Important note
								</span>{" "}
								Deleted categories will be removed from all
								articles.
							</p>
						</div>
					</div>
				</Modal>
			)}
			{dataModalOpen ? (
				<Modal
					style={{ zIndex: "1100" }}
					width={"40%"}
					open={dataModalOpen}
					title={
						<div
							style={{
								fontWeight: "bold",
								justifyContent: "center",
							}}
						>
							{props.type === "categories"
								? "Categories configuration"
								: "Tags configuration"}
						</div>
					}
					onCancel={(e: any) =>
						modalLoading === true
							? notificationCloseModal(e)
							: handle_data_config_modal()
					}
					footer={
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Input
								type="text"
								placeholder={`Add New ${
									props.type === "categories"
										? "Category"
										: "Tag"
								}...`}
								style={{ width: "80%" }}
								value={newDataInputValue}
								allowClear
								onChange={handleNewDataInput}
								onPressEnter={handleNewDataInputConfirm}
								disabled={modalLoading}
							/>
							<PlusCircleOutlined
								style={{
									width: "5%",
									fontSize: "110%",
									paddingLeft: "2%",
								}}
								onClick={handleNewDataInputConfirm}
							/>
						</div>
					}
				>
					{selectedRemoveData.length > 0 ? (
						<div
							style={{
								marginBottom: "2%",
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<Button
								type="primary"
								danger
								style={{ marginRight: "10%" }}
								onClick={() => setConfirmRemoveModal(true)}
							>
								Delete
							</Button>
						</div>
					) : null}

					{modalLoading ? (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Spin size="large" />
						</div>
					) : (
						<Checkbox.Group
							style={{
								display: "grid",
								maxHeight: "50vh",
								overflowY: "auto",
							}}
						>
							{allData
								.filter((item: any) => {
									if (
										/^\s*$/.test(newDataInputValue) === true
									) {
										return item;
									} else if (
										item.label
											.toString()
											.toLowerCase()
											.includes(
												newDataInputValue.toLocaleLowerCase()
											)
									) {
										return item;
									}
								})
								.map((data: any, index: any) => {
									return (
										<div
											key={index}
											style={{
												display: "block",
												margin: "8px",
											}}
										>
											<Checkbox
												key={index}
												value={data.value}
												checked={selectedRemoveData.includes(
													data.value
												)}
												onChange={() =>
													handleSelectedRemoveData(
														data.value
													)
												}
												style={{ width: "5%" }}
												disabled={data.disable}
											/>
											<Input
												style={{ width: "90%" }}
												allowClear
												disabled={data.disable}
												value={data.label}
												onChange={(e: any) =>
													handleEditData(
														e,
														data.value
													)
												}
											/>
										</div>
									);
								})}
						</Checkbox.Group>
					)}
					{/* <div style={{ display: 'grid', height: '50vh', overflowY: 'auto' }}>
						<FixedSizeList height={window.innerHeight * 0.5} itemCount={allData.length} itemSize={50} width="100%" itemData={allData}>
							{renderItem}
						</FixedSizeList>
					</div> */}
				</Modal>
			) : null}
		</>
	);
}

export default ManageModal;
