import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

const UnauthorizedPage = () => {
	const navigate = useNavigate();

	const handleBackHome = () => {
		if (sessionStorage.getItem("isLogin")) {
			navigate("/story");
		} else {
			navigate("/login");
		}
	};

	return (
		<div className="unauthorized-page">
			<Result
				status="404"
				title="404"
				subTitle="Sorry, the page you visited does not exist."
				extra={
					<Button type="primary" onClick={handleBackHome}>
						Back Home
					</Button>
				}
			/>
		</div>
	);
};

export default UnauthorizedPage;
