import {
	Space,
	Card,
	Modal,
	Button,
	Input,
	Switch,
	Select,
	Radio,
	DatePickerProps,
	TimePickerProps,
	TimePicker,
	DatePicker,
	Tooltip,
	message,
	Popconfirm,
	Layout,
} from "antd";
import moment from "moment";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { MinusOutlined, SettingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "../assets/css/ConfigPage.css";

dayjs.extend(customParseFormat);

const EditConfig = (props: any) => {
	const [openState, setOpenState] = useState(false);
	const [data, setData] = useState(props.data);
	const [fullData, setFullData] = useState(props.fullData);

	const [confirmationVisible, setConfirmationVisible] = useState(false);
	const [duplicateEmail, setDuplicateEmail] = useState("");
	const [email, setEmail] = useState("");
	const [authors, setAuthors] = useState("");
	const [defaultAuthor, setDefaultAuthor] = useState<any>(
		props.data.default_author ? props.data.default_author : "No default author"
	);
	const [openInputDefaultAuthor, setOpenInputDefaultAuthor] =
		useState<boolean>(false);

	const [leaveConfirmation, setLeaveConfirmation] = useState(false);

	const [showEmail, setShowEmail] = useState("");
	const [error, setError] = useState(null);
	const [initialData, setInitialData] = useState(data);
	const emailRegex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
	const authorsRegex = /^(\w+ \S+|\S+ \S+)$/;

	const handleStay = () => {
		setConfirmationVisible(false);
	};

	message.config({
		top: 50,
		duration: 3,
	});

	useEffect(() => {
		// console.log("update state:", update);
		setOpenState(props.open || false);
		if (props.data) {
			setData(props.data);
		}
		if (props.fullData) {
			setFullData(props.fullData);
		}
	}, []);

	useEffect(() => {
		if (duplicateEmail !== "") {
			message.error(
				<span style={{ fontSize: "15px" }}>
					<b>{showEmail}</b> has been registered in <b>{duplicateEmail}</b>.
					Please enter a new email.
				</span>
			);
			setDuplicateEmail("");
		}
	}, [duplicateEmail]);

	// function displayError() {
	//     if (duplicateEmail !== "") {
	//         message.error(
	//             <span style={{ fontSize: "15px" }}>
	//                 Duplicate emails found within {duplicateEmail}, please
	//                 correct before updating
	//             </span>
	//         );
	//         setDuplicateEmail("");
	//     }
	// }

	useEffect(() => {
		console.log("data", data);
		console.log("fulldata", fullData);
		console.log("initialData", initialData);
		// When value of data changes, compare it with initial data
		// If there is a difference, set leaveConfirmation to true
		// If there is no difference, set leaveConfirmation to false
		if (JSON.stringify(data) !== JSON.stringify(initialData)) {
			setLeaveConfirmation(true);
		} else {
			setLeaveConfirmation(false);
		}
	}, [data]);

	const saveConfig: any = () => {
		var d = new Date().toUTCString();
		let newdata = { ...data, last_config_date: d };
		console.log("after update:", newdata);
		return new Promise((resolve: any, reject: any) => {
			axios
				.put(`/api/publishers`, newdata)
				// .put(`/publishers`, newdata)
				.then((res: any) => {
					resolve(res.data ? res.data : []);
				})
				.catch((err: any) => {
					console.error(err);
					reject(err);
				});
		});
	};

	const handleOk = () => {
		if (data.authors.includes("")) {
			message.error(
				<span style={{ fontSize: "15px" }}>Invalid author format</span>
			);
			return;
		}

		let invalidEmails = data.contributors_email.filter(
			(email: any) => !email.match(emailRegex)
		);

		var d = new Date().toUTCString();
		console.log("handle ok new date:", d);

		if (invalidEmails.length > 0) {
			message.error(
				<span style={{ fontSize: "15px" }}>Invalid email format</span>
			);
			return;
		}

		if (hasDuplicates(data.contributors_email)) {
			message.error(
				<span style={{ fontSize: "15px" }}>
					Duplicate emails found within the <b>{data.publisher_name}</b>, please
					enter new email.
				</span>
			);
			return;
		} else if (
			hasDuplicatesCheckAll(data.contributors_email, data.publisher_name)
		) {
			// displayError();
			return;
		}

		if (email) {
			if (!email.match(emailRegex)) {
				message.error(
					<span style={{ fontSize: "15px" }}>
						Please Input Valid Email Address
					</span>
				);
				return;
			} else {
				setError(null);
			}
		}

		if (!error) {
			if (initialData !== data) {
				setData({
					...data,
					last_config_date: d,
				});
				saveConfig();
				message.success(
					<span style={{ fontSize: "15px" }}>
						Record is Saved
						<hr></hr>
						Changes to
						<br></br>
						<b>{data.publisher_name} </b>
						<br></br>
						saved successfully.
					</span>
				);

				setOpenState(false);
				if (props.handleCancel && typeof props.handleCancel === "function") {
					props.handleCancel();
				}
			} else {
				message.warning(
					<span style={{ fontSize: "15px" }}>No Changes Made</span>
				);
			}
		}
	};

	const handleCancel = () => {
		setOpenState(false);
		if (props.handleCancel && typeof props.handleCancel === "function") {
			props.handleCancel();
		}
	};

	const handleAddNewEmail = () => {
		var d = data;
		setData({
			...data,
			contributors_email: d.contributors_email.concat(""),
		});
	};
	const handleAddNewAuthors = () => {
		var d = data;
		setData({
			...data,
			authors: d.authors.concat(""),
		});
	};

	const handleUseEmail = (value: any) => {
		setData({ ...data, email: value });
	};

	const handleUseRss = (value: any) => {
		setData({ ...data, rss: value });
	};

	const handleRssDay = (e: any) => {
		console.log(e);
		var d = data.rss_schedule;
		d = {
			...d,
			day: e,
		};
		setData({
			...data,
			rss_schedule: d,
		});
	};

	const handleRssTime = (e: any) => {
		const t = new Date(e);
		const h = t.getUTCHours();
		const m = t.getUTCMinutes();
		const s = t.getUTCSeconds();
		const newTime = new Date(
			t.getFullYear(),
			t.getMonth(),
			t.getDate(),
			h,
			m,
			s
		);
		const utcTime = newTime.toTimeString().substring(0, 8);
		console.log("utctime:", utcTime);
		console.log("utcTime to Locale", moment(utcTime, "HH:mm:ss").local());
		console.log("t", t.toLocaleTimeString());
		setData({
			...data,
			rss_schedule: {
				...data.rss_schedule,
				time: utcTime,
			},
		});
	};

	const urlOptions: any = [
		{
			value: "Sunday",
			label: "Sunday",
		},
		{
			value: "Monday",
			label: "Monday",
		},
		{
			value: "Tuesday",
			label: "Tuesday",
		},
		{
			value: "Wednesday",
			label: "Wednesday",
		},
		{
			value: "Thursday",
			label: "Thursday",
		},
		{
			value: "Friday",
			label: "Friday",
		},
		{
			value: "Saturday",
			label: "Saturday",
		},
	];

	const text = "Are you sure to delete this task?";
	const description = "Delete the task";

	const confirm = () => {
		message.info("Clicked on Yes.");
	};

	function validateEmail(email: string) {
		var reg = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
		if (reg.test(email)) {
			return true;
		}
		alert("Please enter valid email.");
		return false;
	}

	const modalHeader = () => {
		return (
			<Space style={{ fontSize: "18px" }}>
				<SettingOutlined />
				<div>Publisher Configuration Detail</div>
			</Space>
		);
	};
	const { Header, Footer, Sider, Content } = Layout;

	function hasDuplicates(emails: any) {
		return new Set(emails).size !== emails.length;
	}

	function hasDuplicatesCheckAll(emails: any, publisher: any) {
		var found = false;

		props.fullData.map((publisher_item: any) => {
			if (publisher_item.publisher_name === publisher) {
				return;
			}
			emails.map((email: any) => {
				if (publisher_item.contributors_email.includes(email)) {
					found = true;
					setDuplicateEmail(publisher_item.publisher_name);
					setShowEmail(email);
				}
			});
		});
		return found;
	}

	const handleRemove = (index: any) => {
		let d = data.contributors_email;
		message.warning(<span style={{ fontSize: "15px" }}>Email Removed</span>);

		d.splice(index, 1);
		setData({
			...data,
			contributors_email: d,
		});
		setEmail("");
	};

	const handleRemoveAuthors = (index: any) => {
		let d = data.authors;
		message.warning(<span style={{ fontSize: "15px" }}>Author Removed</span>);

		d.splice(index, 1);
		setData({
			...data,
			Authors: d,
		});
		setAuthors("");
	};

	return (
		<>
			<Modal
				title={modalHeader()}
				className={"modal-header"}
				open={openState}
				onOk={handleOk}
				okText="Update Configuration"
				onCancel={() => {
					if (leaveConfirmation === true) {
						setConfirmationVisible(true);
					} else {
						setOpenState(false);
						setConfirmationVisible(false);
					}
				}}
				footer={[
					<Button
						danger
						key="cancel"
						onClick={() => {
							if (leaveConfirmation === true) {
								setConfirmationVisible(true);
							} else {
								setOpenState(false);
								setConfirmationVisible(false);
							}
						}}
					>
						Cancel
					</Button>,
					<Button key="submit" type="primary" onClick={handleOk}>
						Update Configuration
					</Button>,
				]}
				bodyStyle={{
					height: "100%",
					overflow: "auto",
					alignItems: "center",
					justifyContent: "center",
				}}
				width="50%"
				style={{ top: "1vh" }}
			>
				{confirmationVisible && (
					<Modal
						title="Leave Without Saving?"
						open={confirmationVisible}
						onOk={handleStay}
						onCancel={handleCancel}
						className="rounded-modal headermissing"
						footer={[
							<Button key="stay" type="primary" onClick={handleStay}>
								Stay On This Page
							</Button>,
							<Button
								className="custom-button"
								key="leave"
								style={{ float: "left" }}
								onClick={handleCancel}
							>
								Leave
							</Button>,
						]}
					>
						<div className="modal-content" style={{ textAlign: "center" }}>
							<div style={{ fontWeight: "bold", fontSize: "16px" }}>
								Leave Without Saving?
							</div>
							<hr />
							<div
								style={{
									lineHeight: "1",
									padding: "24px 10px 0px 10px",
									fontSize: "14px",
								}}
							>
								<p>You have unsaved changes that will be lost if you leave</p>
								<p className="important-note">
									Important note: Data is{" "}
									<span style={{ fontWeight: "600" }}>NOT</span> auto-updated
								</p>
							</div>
						</div>
					</Modal>
				)}
				<div className="box">
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "40% auto",
							width: "100%",
						}}
					>
						<div style={{ fontWeight: "bold" }}>Publisher Name:</div>

						<div style={{ fontWeight: "bold" }}>{data.publisher_name}</div>
					</div>
				</div>

				<div className="box">
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "40% auto",
							width: "100%",
							alignItems: "center",
						}}
					>
						<div style={{ fontWeight: "bold" }}>Default Author:</div>

						{openInputDefaultAuthor === false ? (
							<div
								style={{ fontWeight: "bold" }}
								onClick={() => {
									setOpenInputDefaultAuthor(true);
								}}
							>
								<Tooltip placement="top" title={"Double click to edit"}>
									{/^\s*$/.test(defaultAuthor) !== true
										? defaultAuthor
										: "No default author"}
								</Tooltip>
							</div>
						) : (
							<Input
								style={{ width: "100%" }}
								placeholder="Please enter default author name..."
								value={defaultAuthor}
								onChange={(e: any) => {
									setDefaultAuthor(e.target.value);
									setData({ ...data, default_author: e.target.value });
								}}
								onBlur={(e: any) => {
									setOpenInputDefaultAuthor(false);
									setDefaultAuthor(e.target.value);
									setData({ ...data, default_author: e.target.value });
								}}
							/>
						)}
					</div>
				</div>

				<div className="box">
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "40% auto",
							width: "100%",
						}}
					>
						<div style={{ fontWeight: "bold" }}>Authors List:</div>

						<div
							style={{
								marginTop: "12px",
							}}
						>
							{data.authors.map((authors: any, index: any) => {
								return (
									<Space
										key={index}
										style={{
											width: "100%",
										}}
									>
										<Input
											key={`i-${index}`}
											value={authors}
											size="large"
											onBlur={(e) => setAuthors(e.target.value)}
											onChange={(e: any) => {
												let d = [...data.authors];
												d[index] = e.target.value;
												setData({
													...data,
													authors: d,
												});
											}}
											placeholder="Enter Authors"
											style={{
												width: "250px",
												fontFamily: "sans-serif",
												marginBottom: "10px",
											}}
										/>
										<Button
											style={{
												marginBottom: "10px",
											}}
											danger
											size="large"
											onClick={() => handleRemoveAuthors(index)}
										>
											<MinusOutlined />
										</Button>
									</Space>
								);
							})}
							<div style={{ maxWidth: "250px" }}>
								<Button
									onClick={handleAddNewAuthors}
									style={{
										width: "100%",
										marginBottom: "15px",
										marginTop: "15px",
									}}
								>
									+ Add New Author
								</Button>
							</div>
						</div>
					</div>
				</div>

				<div className="box">
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "40% auto",
							width: "100%",
						}}
					>
						<div style={{ fontWeight: "bold" }}>RSS Configuration:</div>
						<div>
							{data.rss_link ? (
								<>
									<div>
										<div>
											<Switch
												size="default"
												checkedChildren="Enable RSS Ingestion"
												unCheckedChildren="Disable RSS Ingestion"
												checked={data.rss ? true : false}
												onChange={(e: any) => {
													console.log("switch rss:", e);
													handleUseRss(e);
												}}
											/>
										</div>
										{data.rss && (
											<>
												<div
													style={{
														marginTop: "25px",
													}}
												>
													<span
														style={{
															fontWeight: "bold",
														}}
													>
														RSS Link
													</span>
													<Button
														type="link"
														style={{
															fontStyle: "italic",
														}}
														onClick={() =>
															window.open(
																data.rss_link,
																"newwindow",
																"width=600,height=400"
															)
														}
													>
														{data.rss_link}
													</Button>
												</div>
												<hr />
												<div
													style={{
														marginTop: "15px",
													}}
												>
													<span
														style={{
															fontWeight: "bold",
														}}
													>
														Saving Option
													</span>
													<span className="text">
														<Radio.Group
															defaultValue={data.rss_mode}
															buttonStyle="solid"
															onChange={(e: any) => {
																console.log("radio rss mode", e.target.value);
																setData({
																	...data,
																	rss_mode: e.target.value,
																});
															}}
														>
															<Radio.Button value="auto">Autosave</Radio.Button>
															<Radio.Button value="manual">
																Manual Save
															</Radio.Button>
														</Radio.Group>
													</span>
												</div>
												{data.rss == true && data.rss_mode == "auto" && (
													<Space
														style={{
															marginTop: "3%",
														}}
													>
														<Select
															options={urlOptions}
															value={data.rss_schedule.day}
															onSelect={handleRssDay}
															style={{
																width: "200px",
															}}
															placeholder="Select Day"
														/>
														<TimePicker
															// Data in DB should be UTC format. When display, we should use Locale time
															defaultValue={
																data.rss_schedule.time
																	? moment(
																			data.rss_schedule.time,
																			"HH:mm:ss"
																	  ).local()
																	: moment("00:00:00", "HH:mm:ss")
															}
															onOk={handleRssTime}
														/>
													</Space>
												)}
											</>
										)}
									</div>
								</>
							) : (
								<>
									<div>No RSS link provided</div>
								</>
							)}
						</div>
					</div>
				</div>

				<div className="box">
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "40% auto",
							width: "100%",
						}}
					>
						<div style={{ fontWeight: "bold" }}>URL Configuration</div>
						<div>
							<Radio.Group
								defaultValue={data.url_mode}
								buttonStyle="solid"
								onChange={(e: any) => {
									// console.log("radio", e.target.value);
									setData({
										...data,
										url_mode: e.target.value,
									});
								}}
							>
								<Tooltip
									placement="bottom"
									title={() => {
										return (
											<div
												style={{
													textAlign: "center",
												}}
											>
												Save to Database Only
											</div>
										);
									}}
								>
									<Radio.Button
										value="save to database"
										disabled={data.url_mode ? false : true}
									>
										Save to Database
									</Radio.Button>
								</Tooltip>

								<Tooltip
									placement="bottom"
									title={() => {
										return (
											<div
												style={{
													textAlign: "center",
												}}
											>
												Save to Both Database and Wordpress
											</div>
										);
									}}
								>
									<Radio.Button
										value="save to wordpress"
										disabled={data.url_mode ? false : true}
									>
										Save to Wordpress
									</Radio.Button>
								</Tooltip>
							</Radio.Group>
						</div>
					</div>
				</div>

				<div className="box">
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "40% auto",
							width: "100%",
						}}
					>
						<div style={{ fontWeight: "bold" }}>Contributors Email</div>
						<div>
							<div>
								<Switch
									checkedChildren="Enable Email Ingestion"
									unCheckedChildren="Disable Email Ingestion"
									checked={data.email ? true : false}
									onChange={(e: any) => {
										console.log("switch email:", e);
										handleUseEmail(e);
									}}
								/>
							</div>

							{data.email && (
								<>
									<div
										style={{
											marginTop: "20px",
										}}
									>
										<span
											style={{
												fontWeight: "bold",
												marginBottom: "10px",
											}}
										>
											Registered Emails
										</span>
									</div>
									<div
										style={{
											marginTop: "12px",
										}}
									>
										{data.contributors_email.map((email: any, index: any) => {
											return (
												<Space
													key={index}
													style={{
														width: "100%",
													}}
												>
													<Input
														key={`i-${index}`}
														value={email}
														size="large"
														onBlur={(e) => setEmail(e.target.value)}
														onChange={(e: any) => {
															let d = [...data.contributors_email];
															d[index] = e.target.value;
															setData({
																...data,
																contributors_email: d,
															});
														}}
														placeholder="Enter Email"
														style={{
															width: "250px",
															fontFamily: "sans-serif",
															marginBottom: "10px",
														}}
													/>
													<Button
														style={{
															marginBottom: "10px",
														}}
														danger
														size="large"
														onClick={() => handleRemove(index)}
													>
														<MinusOutlined />
													</Button>
												</Space>
											);
										})}
									</div>
									<div style={{ maxWidth: "250px" }}>
										<Button
											onClick={handleAddNewEmail}
											style={{
												width: "100%",
												marginBottom: "15px",
												marginTop: "15px",
											}}
										>
											+ Add New Emails
										</Button>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default EditConfig;
