import { Menu } from "antd";
import { useState, useEffect } from "react";
import "../App.css";

const MenuComponent = (props: any) => {

	const getActiveMenu = () => {
		const current = window.location.pathname;
		// remove the first slash
		const path = current.slice(1);
		return path;
	}

	const [current, setCurrent] = useState(getActiveMenu);

	useEffect(() => {
		console.log("getBaseUrl", current);
	}, [current]);

	const onClick = (e: any) => {
		console.log("click ", e);
		setCurrent(e.key);
	};

	// check if the role of the user is the same as the role of the menu item
	const filteredItems = props.items.filter((item: any) => {
		if (item.role === sessionStorage.getItem("role") || !item.role) {
			return true;
		}
		return false;
	});

	return (
		<>
			<Menu
				onClick={onClick}
				selectedKeys={[current]}
				items={filteredItems}
				mode="horizontal"
				className="menu"
			/>
		</>
	);
};

export default MenuComponent;
