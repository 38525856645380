import React, { Component } from "react";
import Events from "./../lib/events";
import "../App.css";
import file from "../../package.json";

// Events.on("customclicking", () => {
// 	alert("hisdfd");
// });

export class Footer extends Component {
	render() {
		return (
			<>
				<div
					style={{
						marginLeft: "5px",
						fontSize: "11px",
						position: "absolute",
						left: 10,
					}}
				>
					Version {file.version}
				</div>
				<div>Having problems? Please contact us at 082-XXXXXXX</div>
			</>
		);
	}
}

export default Footer;
