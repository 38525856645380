import React from "react";
import "../assets/css/ConfigPage.css";
import {
  Table,
  Alert,
  Button,
  Input,
  Modal,
  message,
  Tooltip,
  Select,
  DatePicker,
  Popconfirm,
  Space,
} from "antd";
import {
  InfoCircleOutlined,
  PlusOutlined,
  AudioOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { useEffect, useState } from "react";
import EditConfig from "../components/EditConfig";
import IO from "../lib/socketio";
import dayjs from "dayjs";
import type { RangePickerProps } from "antd/es/date-picker";
import axios from "axios";

const ConfigPage = () => {
  // States
  const [data, setData] = useState([]);
  const [fulldata, setFulldata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [searchPublisher, setSearchPublisher] = useState(false);
  const [filter, setFilter]: any = useState({});
  const [loadingRow, setLoadingRow]: any = useState([]);
  // const [pickDateModal, setPickDateModal] = useState(false);
  const [dateRange, setDateRange] = useState([]);

  const { RangePicker } = DatePicker;

  const fetchData = () => {
    setLoading(true);
    fetch(`/api/publishers`)
      .then((res) => res.json())
      .then((res) => {
        const DATA = res.data.map((req: any) => ({
          key: req._id,
          id: req.id,
          publisher_name: req.publisher_name,
          rss: req.rss,
          rss_link: req.rss_link,
          email: req.email,
          rss_mode: req.rss_mode,
          rss_schedule: req.rss_schedule,
          url_mode: req.url_mode,
          authors: req.authors,
          default_author: req.default_author,
          contributors_email: req.contributors_email,
          last_config_date: req.last_config_date ? req.last_config_date : "",
          last_fetch_rss: req.last_fetch_rss ? req.last_fetch_rss : "",
        }));
        DATA.sort((a: any, b: any) =>
          a.publisher_name.localeCompare(b.publisher_name)
        );
        return DATA;
      })
      .then((DATA) => {
        setData(DATA);
        setFulldata(DATA);
        setLoading(false);
      });
  };

  useEffect(() => {
    IO.on("rss_done", (msg: any) => {
      console.log("rss_done", msg);
      var array = loadingRow;
      array = array.filter(
        (item: any) => item.publisher_name !== msg["publisher_name"]
      );
      setLoadingRow(array);
      // localStorage.setItem("loadingRow", JSON.stringify(array));
      if (!showModal) {
        fetchData();
      }
    });
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log("show modal", showModal);
    fetchData();
    setSearchPublisher(false);
    // handleFilter();
  }, [showModal]);

  // useEffect(() => {
  // 	console.log("data", data);
  // }, [data]);

  useEffect(() => {
    console.log("loading row", loadingRow);
  }, [loadingRow]);

  useEffect(() => {
    handleFilter();
  }, [filter]);

  useEffect(() => {
    console.log("daterange", dateRange);
  }, [dateRange]);

  const fetchRSS = (record: any, rowIndex: any, duration: any) => {
    var array = loadingRow;
    const item = {
      publisher_name: record.publisher_name,
      row: rowIndex,
    };
    array.push(item);
    setLoadingRow(array);
    // localStorage.setItem("loadingRow", JSON.stringify(array));

    const msg = {
      publisher_name: record.publisher_name,
      rss_link: record.rss_link,
      start_date: duration[0],
      end_date: duration[1],
      userId: localStorage.getItem("email"),
    };
    console.log("fetch rss", msg);
    IO.emit("fetch-rss", msg);
  };

  const handleFilter = () => {
    if (Object.keys(filter).length == 0) {
      console.log("filter empty", filter);
      setData(fulldata);
    } else {
      console.log("filter not empty", filter?.publisher_name);
      var newdata;
      newdata = fulldata.filter((element: any) =>
        element.publisher_name.includes(filter.publisher_name)
      );
      setData(newdata);
    }
  };

  const formatDate = (value: any) => {
    const d = new Date(value);
    return d.toLocaleString();
  };

  const handleConfigModal = (rec: any) => {
    setShowModal(!showModal);
    setRowData(rec);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const publisherHeader = () => {
    const publisherOptions = () => {
      var publishers: any = [];
      fulldata.map((d: any) => {
        publishers.push({
          value: d.publisher_name,
          label: d.publisher_name,
        });
      });
      return publishers;
    };

    const handleSearchPublisher = () => {
      setSearchPublisher(true);
    };

    const handleSelectPublisher = (value: any) => {
      setSearchPublisher(false);
      setFilter({ ...filter, publisher_name: value });
    };

    return (
      <>
        {searchPublisher ? (
          <Select
            showSearch
            allowClear
            placeholder="Publisher Name"
            options={publisherOptions()}
            style={{ width: "100%", fontWeight: "bold" }}
            onClick={() => setSearchPublisher(true)}
            onSelect={handleSelectPublisher}
            onClear={() => {
              setFilter({});
              setSearchPublisher(false);
            }}
          />
        ) : (
          <a
            style={{ fontWeight: "bold", color: "#000000" }}
            onClick={handleSearchPublisher}
          >
            Publisher Name
          </a>
        )}
      </>
    );
  };

  const columns: any = [
    {
      title: publisherHeader,
      dataIndex: "publisher_name",
      render: (value: any, record: any) => (
        <a
          onClick={() => {
            handleConfigModal(record);
          }}
        >
          {value}
        </a>
      ),
      //   sorter: (a: any, b: any) => {
      //     return a["publisher_name"]
      //       .toString()
      //       .localeCompare(b["publisher_name"].toString());
      //   },
    },
    {
      title: "Last Edit Config",
      dataIndex: "last_config_date",
      render: (value: any) => (value ? formatDate(value) : <i>Not Edited</i>),
      sorter: (a: any, b: any) => {
        return a["last_config_date"]
          .toString()
          .localeCompare(b["last_config_date"].toString());
      },
      align: "center",
      width: "15%",
    },
    {
      title: "Action",
      render: (text: any, record: any, rowIndex: any) => {
        const handleLoadButton = () => {
          // console.log(loadingRow, rowIndex);
          // if (loadingRow.includes(rowIndex)) {
          // 	return true;
          // } else {
          // 	return false;
          // }
          // let a: any = localStorage.getItem("loadingRow");
          // if (localStorage.getItem("loadingRow")) {
          // 	const array: any = JSON.parse(a);
          // 	array.map((item: any) => {
          // 		if (item.row === rowIndex) {
          // 			load = true;
          // 		}
          // 	});
          // }
          var load = false;
          loadingRow.map((item: any) => {
            if (item.row === rowIndex) {
              load = true;
            }
          });

          return load;
        };

        const disabledDate: RangePickerProps["disabledDate"] = (current) => {
          return current && current > dayjs().endOf("day");
        };

        const handleDateChange = (start: any, end: any) => {
          var range: any = [start, end];
          setDateRange(range);
        };

        const handleDateRange = (e: any) => {
          const startDate = new Date(e[0]).toUTCString();
          const endDate = new Date(e[1]).toUTCString();
          console.log(startDate, endDate);
          handleDateChange(startDate, endDate);
        };

        const showRangePicker = () => {
          return (
            <Space direction="vertical">
              <div style={{ fontWeight: "500" }}>Choose range of date</div>
              <RangePicker
                disabledDate={disabledDate}
                onCalendarChange={handleDateRange}
                allowClear
                popupStyle={{
                  zIndex: 9999,
                }}
              />
            </Space>
          );
        };

        const handlePopconfirm = () => {
          const duration = dateRange;
          fetchRSS(record, rowIndex, duration);
          console.log("confirm", dateRange);
        };

        const handleFetchRSS = () => {
          const date = new Date();
          const duration = [date, date];
          fetchRSS(record, rowIndex, duration);
        };

        return (
          <>
            {/* <Popconfirm
                            title={showRangePicker}
                            okText="Choose Date"
                            onConfirm={handlePopconfirm}
                            icon={null}
                        >
                            <Button
                                disabled={
                                    record.rss && record.rss_mode == "manual"
                                        ? false
                                        : true
                                }
                                loading={handleLoadButton()}
                            >
                                Fetch RSS Articles
                            </Button>
                        </Popconfirm> */}
            <Button
              disabled={
                record.rss && record.rss_mode == "manual" ? false : true
              }
              onClick={handleFetchRSS}
              loading={handleLoadButton()}
            >
              Fetch RSS Articles
            </Button>
          </>
        );
      },
      align: "center",
      width: "10%",
    },
    {
      title: "Last Fetch",
      dataIndex: "last_fetch_rss",
      align: "center",
      width: "15%",
      sorter: (a: any, b: any) => {
        return a["last_fetch_rss"]
          .toString()
          .localeCompare(b["last_fetch_rss"].toString());
      },
      render: (value: any) =>
        value ? formatDate(value) : <i>Never Fetched</i>,
    },
  ];

  return (
    <>
      <div className="body-content" style={{ textAlign: "center" }}>
        <h1>Publisher Configuration</h1>
        {showModal && (
          <EditConfig
            open={showModal}
            handleCancel={handleCancel}
            handleOk={() => setShowModal(false)}
            data={rowData}
            fullData={fulldata}
          />
        )}
        <div
          style={{
            display: Object.keys(filter).length != 0 ? "flex" : "none",
            margin: "5px 0px",
          }}
        >
          <Button
            className="button-green"
            onClick={() => {
              setFilter({});
              setSearchPublisher(false);
            }}
          >
            Reset Filter
          </Button>
        </div>
        <Table
          rowClassName={(record: any, index: any) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          size={"small"}
          bordered
          loading={loading}
          pagination={{
            total: data.length,
            pageSize: pageSize,
            current: currentPage,
            onChange: (page) => page !== currentPage && setCurrentPage(page),
            onShowSizeChange: (current, size) => {
              setCurrentPage(1);
              setPageSize(size);
            },
          }}
        />
      </div>
    </>
  );
};

export default ConfigPage;
