import { Form, Button, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import "../App.css";
import axios from "axios";
import ArrnLogo from "../assets/img/Logo3-removebg-preview.png";

const ForgetPasswordPage = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const items = [
		{
			key: "email",
			name: "email",
			rules: [
				{
					required: true,
					message: "Please enter your email!",
				},
				{
					type: "email",
					message: "Please enter a valid email!",
				},
			],
			validateTrigger: "onBlur",
			component: (
				<Input
					placeholder="Please Enter Email"
					style={{ width: "100%", textAlign: "center" }}
				/>
			),
		},
	];

	const onFinish = (values: any) => {
		console.log("Forget password:", values);
		return new Promise((resolve: any, reject: any) => {
			axios
				.post(
					`/api/users/forgetPassword`,
					values
				)
				.then((res) => {
					console.log(res);
					if (res.data.success) {
						notification.success({
							message: "Password Reset Link Sent!",
							description: "Please check your email.",
						});
					} else {
						notification.error({
							message: "Password Reset Link Failed!",
							description: res.data.message,
						});
					}
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<div className="forget-password-page background-image">
			<div className="logo">
				<img src={ArrnLogo} alt="logo" width={"500vw"}></img>
			</div>
			<div className="form-box">
				<h1>Forget Password</h1>
				<hr style={{ margin: "10px 0px 30px 0px" }} />
				<div>
					<p>
						Please enter your email to receive the reset link for
						your password.
					</p>
				</div>
				<Form
					form={form}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					{items.map((item: any) => (
						<Form.Item {...item}>{item.component}</Form.Item>
					))}
					<Form.Item>
						<Button
							className="button-green"
							type="primary"
							htmlType="submit"
						>
							Send Email
						</Button>
					</Form.Item>
					<Button
						type="link"
						className="button-link"
						onClick={() => navigate("/login")}
					>
						Back to Login Page
					</Button>
				</Form>
			</div>
		</div>
	);
};

export default ForgetPasswordPage;
