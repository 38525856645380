import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

const UnauthorizedPage = () => {
	const navigate = useNavigate();

	const handleBackHome = () => {
		if (sessionStorage.getItem("isLogin")) {
			navigate("/story");
		} else {
			navigate("/login");
		}
	};

	return (
		<div className="unauthorized-page">
			<Result
				status="403"
				title="403"
				subTitle="Sorry, you are not authorized to access this page."
				extra={
					<Button type="primary" onClick={handleBackHome}>
						Back Home
					</Button>
				}
			/>
		</div>
	);
};

export default UnauthorizedPage;
