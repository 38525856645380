import { useEffect, useState } from "react";
import { Button, Descriptions, Modal, Space, Row, Col, Empty, } from "antd";
import { FileSearchOutlined, CheckOutlined } from "@ant-design/icons";

function Contents(props: any) {
	const [modalOpen, setModalOpen] = useState<boolean>(false);

	// Format the article upload date
	const formatDate = (data:string) => {
		const newdate = new Date(data);
		return newdate.toLocaleString();
	};
	// Format images to 3 images/row
	const formatImages = (data:any[]) => {
		if((data !== undefined) && (data !== null) && (data.length !== 0)){
			// Splie images into rows
			let tempData:any = [];
			for (let i = 0; i < data.length; i += 3) {
				const row = data.slice(i, i + 3);
				tempData.push(row);
			}
			return tempData;
		}
		return [];
	}

	const formatContent = (data:string) => {
		if((data != undefined) && (data != null)){
			const tempContainer = document.createElement("div");
			tempContainer.innerHTML = data;
		
			const images = tempContainer.getElementsByTagName("img");
			for (let i = 0; i < images.length; i++) {
			images[i].style.maxWidth = "100%";
			images[i].style.height = "auto";
			}
			return tempContainer.innerHTML;
		}
		return "Content is empty !";
	}

	// Handle close modal
	const handleClose = () => {
		setModalOpen(false);
		if (props.handleCancel && typeof props.handleCancel === "function") {
			props.handleCancel();
		}
	}
	useEffect(()=>{
		setModalOpen(props.open);
	},[]);

	return (
		<>
			<Modal
				className="modalContainer"
				width={"72%"}
				title={
					<Space>
						<FileSearchOutlined />
						<div>Preview</div>
					</Space>
				}
				open={modalOpen}
				onOk={handleClose}
				onCancel={handleClose}
				footer={
					<Button
						className="button-green"
						onClick={handleClose}
					>
						Close
					</Button>
				}
			>
				<Descriptions bordered column={1}>
					<Descriptions.Item label="Title">
						{props.data.title !== undefined && props.data.title != null?
							props.data.title:
							"Title not found	!"
						}
					</Descriptions.Item>
					
					{props.data.type == "url"?
						<Descriptions.Item label="Source">
							{props.data.url !== undefined && props.data.url != null?
								<a href={props.data.url} target="_blank">{props.data.url}</a>:
								"Article link not found	!"
							}
						</Descriptions.Item>:
						null
					}

					<Descriptions.Item label="Upload Date">
						{props.data.upload_date !== undefined && props.data.upload_date != null?
							formatDate(props.data.upload_date):
							"Published date not found!"
						}
					</Descriptions.Item>
					
					<Descriptions.Item label="Publisher">
						{props.data.publisher !== undefined && props.data.publisher != null?
							props.data.publisher:
							"Publisher not found!"
						}
					</Descriptions.Item>

					<Descriptions.Item label="Author">
						{props.data.author !== undefined && props.data.author !== null && props.data.author.length > 0 ? (
							<ul style={{ margin: "0px", padding: "0px 24px" }}>
								{props.data.author.map((author: any) => (
									<li key={`${author}-${props.data.key}`}>
										{author}
									</li>
								))}
							</ul>
						) : (
							<i>Author not found!.</i>
						)}
					</Descriptions.Item>

					<Descriptions.Item label="Categories">
						{props.data.categories !== undefined && props.data.categories !== null && props.data.categories.length > 0 ? (
							<ul style={{ margin: "0px", padding: "0px 24px" }}>
								{props.data.categories.map((categories: any) => (
									<li key={`${categories.name}-${props.data.key}`}>
										{categories.name}
									</li>
								))}
							</ul>
						) : (
							<i>No categories found.</i>
						)}
					</Descriptions.Item>

					<Descriptions.Item label="Tags">
						{props.data.tags !== undefined && props.data.tags !== null && props.data.tags.length > 0 ? (
							<ul style={{ margin: "0px", padding: "0px 24px" }}>
								{props.data.tags.map((tags: any) => (
									<li key={`${tags.name}-${props.data.key}`}>
										{tags.name}
									</li>
								))}
							</ul>
						) : (
							<i>No tags found.</i>
						)}
					</Descriptions.Item>

					{props.data.type == "url"?
							<Descriptions.Item label="Image(s)">
							{formatImages(props.data.images).length == 0?
								<Empty style={{height:"5vh"}} image={Empty.PRESENTED_IMAGE_SIMPLE}/>:
								<div style={{maxHeight:"30vh",overflowY:"auto"}}>
									{formatImages(props.data.images).map((col:any, rowIndex:any) => (
										<Row key={rowIndex} className="rowContainer">
											{col.map((img:any, colIndex:any) => (
												<Col key={img._id} className={`colContainer${img.selected==true?"-selected":""}`}>
													<img src={img.src} className="featureImage"/>
													{img.selected==true?
														<p className="featureImageSelected">Selected 
															<CheckOutlined style={{float:"right", marginTop:"2%", fontWeight:"bold"}}/>
														</p>:
														null
													}
												</Col>
											))}
										</Row>
									))}	
								</div>
							}
						</Descriptions.Item>:
						null
					}
				
					<Descriptions.Item label="Content">
						<div style={{maxHeight:"60vh", maxWidth:"56vw", overflow:"auto"}}
						dangerouslySetInnerHTML={{ __html: formatContent(props.data.contents)}} />
					</Descriptions.Item>
				</Descriptions> 
			</Modal>
		</>
	);
}

export default Contents;
