import { Input, Form, Button, Space, notification } from "antd";
import ArrnLogo from "../assets/img/Logo3-removebg-preview.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import "../assets/css/LoginPage.css";
import "../App.css";

const LoginPage = (props: any) => {
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const formData = [
		{
			key: "username",
			name: "username",
			rules: [{ required: true, message: "Please input your username!" }],
			validateTrigger: "onBlur",
			component: <Input placeholder="Username/Email" />,
		},
		{
			key: "password",
			name: "password",
			rules: [{ required: true, message: "Please input your password!" }],
			validateTrigger: "onBlur",
			component: <Input.Password placeholder="Password" />,
		},
	];

	// post request to login on submit
	const onFinish = (data: any) => {
		console.log("Creds:", data);
		return new Promise((resolve: any, reject: any) => {
			axios
				.post(`/api/login`, data)
				.then((res: any) => {
					// if login is successful, save the token in local storage
					console.log(res);
					if (res.data.success) {
						props.canLogin(res.data, true); // create session for user
					} else {
						notification.error({
							message: "Login Failed",
							description: res.data.message,
						});
					}

					console.log(res);
					resolve(res);
				})
				.catch((err: any) => {
					console.log(err);
					reject(err);
				});
		});
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<div className="login-page background-image">
			<div className="logo">
				<img src={ArrnLogo} alt="logo" width={"500vw"}></img>
			</div>
			<div className="form-box">
				<h1>Login</h1>
				<hr style={{ margin: "10px 0px 30px 0px" }} />
				<Form
					className="login-form"
					form={form}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					{formData.map((item: any) => (
						<Form.Item {...item}>{item.component}</Form.Item>
					))}

					{/* Login Button */}
					<Form.Item>
						<Button
							className="button-green"
							style={{
								width: "100%",
								borderRadius: "15px",
								marginTop: "10px",
								fontWeight: "700",
							}}
							size={"large"}
							htmlType="submit"
						>
							Log in
						</Button>
					</Form.Item>
					<div className="button-container">
						<Button
							type="link"
							className="button-link"
							onClick={() => {
								// navigate to forget password page
								navigate("/forgetPassword");
							}}
						>
							Forget Password
						</Button>
						<div className="button-bar" />
						<Button
							type="link"
							className="button-link"
							onClick={() => {
								// navigate to register page
								navigate("/register");
							}}
						>
							Register New User
						</Button>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default LoginPage;
