import { Layout } from "antd";
import "../App.css";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

const LayoutComponent = (props: any) => {
	return (
		<Layout>
			<Layout.Header className="page-header">
				<Header handleLogout={props.handleLogout} />
			</Layout.Header>

			<Layout.Content className="body-content">
				{props.children}
			</Layout.Content>

			<Layout.Footer className="page-footer">
				<Footer />
			</Layout.Footer>
		</Layout>
	);
};

export default LayoutComponent;
