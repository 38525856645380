import React from "react";
import { Input, Button, Space, Select, DatePicker, Checkbox, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState, useRef, useEffect } from "react";
import moment from "moment";
import { stat } from "fs";
import { title } from "process";

export const TransformedColumns = (columns: any, data: any) => {
	const [searchModes, setSearchModes] = useState({});
	const [filteredData, setFilteredData] = useState(data);
	const [openPicker, setOpenPicker] = useState(true);
	const [statusChecked, setStatusChecked] = useState<any>([]);
	const { Option } = Select;

	useEffect(() => {
		console.log("searchModes:", searchModes);
	}, [searchModes]);

	const handleSearchModeToggle = (columnName: any) => {
		setSearchModes((prevSearchModes: any) => ({
			[columnName]: !prevSearchModes[columnName],
		}));
	};

	const handleClearSearch = (columnName: any) => {
		// remove dataindex from searchModes
		setSearchModes((prevSearchModes: any) => {
			const { [columnName]: _, ...rest } = prevSearchModes;
			return rest;
		});
		setStatusChecked([]);
	};

	const handleSort = (column: any) => {
		const { dataIndex, enableSearch, searchType } = column;
		// if column name is in searchModes, then disable sorter
		// else return the previous sorter function
		if (enableSearch && dataIndex in searchModes) {
			// console.log("disable sorter");
			return false;
		}
		// if search select
		if (searchType === "select") {
			return (a: any, b: any) => {
				return a[dataIndex].localeCompare(b[dataIndex]);
			};
		}
		// if search date
		if (searchType === "date") {
			return (a: any, b: any) => {
				const date1 =
					a[dataIndex] === null ? "" : a[dataIndex].toString();
				const date2 =
					b[dataIndex] === null ? "" : b[dataIndex].toString();
				return date1.localeCompare(date2);
			};
		}
	};

	const handleFilter = (value: any, dataIndex: any, type: any) => {
		// console.log("handleFilter:", value, dataIndex);
		const _data = data.filter((item: any) => {
			// Written by Siew
			// Start
			if (type === "checkbox") {
				if (item[dataIndex] !== null) {
					return value.every((data: any) =>
						item[dataIndex].includes(data)
					);
				}
			}
			// End
			else if (type === "select") {
				if (item[dataIndex] !== null) {
					return item[dataIndex].toString().includes(value);
				}
				return false;
			} else if (type === "date") {
				if (item[dataIndex] !== null) {
					// change string to date
					let _date: any = moment(item[dataIndex]);
					// Change the datetime of the data to a locale string before comparing
					_date = _date
						.locale("en", {
							year: "numeric",
							month: "2-digit",
							day: "2-digit",
						})
						.format("YYYY-MM-DD");

					if (value === null) {
						// If the search value is null, then return all data with null date
						return _date === null;
					} else {
						return _date === value.format("YYYY-MM-DD");
					}
				}
				return false;
			}
		});
		console.log("handleFilter:", value, dataIndex);
		setFilteredData(_data);
	};

	const getColumnHeader = (
		column: any,
		searchMode: any,
		handleSearchModeToggle: any
	) => {
		const { title, dataIndex, enableSearch, searchType } = column;

		const get_options = () => {
			const distinctData = data
				.filter((item: any) => item[dataIndex] !== null)
				.reduce((accumulator: any, item: any) => {
					// Status
					if (typeof item[dataIndex] === "object") {
						for (let x = 0; x < item[dataIndex].length; x++) {
							const existingItem = accumulator.find(
								(t: any) => t.value === item[dataIndex][x]
							);
							if (!existingItem) {
								accumulator.push({
									label:
										item[dataIndex][x]
											.charAt(0)
											.toUpperCase() +
										item[dataIndex][x].slice(1),
									value: item[dataIndex][x],
								});
							}
						}
						return accumulator;
					} else {
						const existingItem = accumulator.find(
							(t: any) => t.value === item[dataIndex]
						);
						if (!existingItem) {
							accumulator.push({
								label:
									item[dataIndex].charAt(0).toUpperCase() +
									item[dataIndex].slice(1),
								value: item[dataIndex],
							});
						}
						return accumulator;
					}
				}, []);

			return distinctData;
		};

		const handleSearchEnter = (value: any, type: any) => {
			// Perform search logic here for the specific column (dataIndex)
			console.log(`Search value for ${dataIndex}:`, value);
			handleFilter(value, dataIndex, type);
			handleClearSearch(dataIndex);
		};

		// Written by Siew
		// To handle checkbox in filter
		const handleCheckbox = (value: any, type: any) => {
			let checked = [];
			const existing = statusChecked.includes(value);
			if (existing === true) {
				checked = statusChecked.filter((item: any) => item !== value);
				setStatusChecked(checked);
				handleFilter(checked, dataIndex, type);
			} else {
				checked = statusChecked;
				checked.push(value);
				setStatusChecked(checked);
				handleFilter(checked, dataIndex, type);
			}
		};

		// Creating 2 Buttons for the Date Picker to pick "No Date" and "Today"

		const datePickerCustomButton = () => {
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Button
						type="link"
						onClick={() => {
							handleSearchEnter(null, "date");
							setOpenPicker(false);
						}}
					>
						No Date
					</Button>
					<Button
						type="link"
						onClick={() => {
							handleSearchEnter(moment(), "date");
							setOpenPicker(false);
						}}
					>
						Today
					</Button>
				</div>
			);
		};

		if (enableSearch && searchMode) {
			// Written by Siew
			// Search for Status
			if (searchType === "checkbox") {
				// setSearchStatus(true);
				const list: any = get_options().map(
					(item: any, index: number) => {
						let color = "";
						if (item.label === "DB") {
							color = "geekblue";
						} else if (item.label === "WP") {
							color = "cyan";
						} else if (item.label === "DIS") {
							color = "green";
						}

						return {
							value: item.value,
							label: (
								<Checkbox
									key={index}
									checked={statusChecked.includes(item.value)}
									style={{ float: "left" }}
									// onClick={()=>handleCheckbox(item.value,"checkbox")}
								>
									<Tag
										color={
											item.label.includes("!")
												? "red"
												: color
										}
										// onClick={()=>handleCheckbox(item.value,"checkbox")}
									>
										{item.label.toUpperCase()}
									</Tag>
								</Checkbox>
							),
						};
					}
				);

				return (
					<Select
						getPopupContainer={(trigger) => trigger.parentElement}
						style={{ width: "100%", fontWeight: "normal" }}
						mode="multiple"
						placeholder={`Search ${title}`}
						allowClear
						showArrow
						showSearch
						value={[]}
						options={list}
						filterOption={(inputValue: string, option: any) =>
							option.value
								.toLowerCase()
								.includes(inputValue.toLowerCase())
						}
						onBlur={() => {
							handleClearSearch(dataIndex);
						}}
						onChange={(selectedValue: any) =>
							handleCheckbox(selectedValue[0], "checkbox")
						}
					/>
				);
			} else if (searchType === "select") {
				return (
					<Select
						placeholder={`Search ${title}`}
						showSearch
						allowClear
						autoFocus={true}
						options={get_options()}
						style={{ width: "100%", fontWeight: "normal" }}
						onChange={(value: any) => {
							handleSearchEnter(value, "select");
						}}
						onClear={() => {
							handleClearSearch(dataIndex);
						}}
						onBlur={() => {
							handleClearSearch(dataIndex);
						}}
					/>
				);
			} else if (searchType === "date") {
				return (
					<DatePicker
						allowClear
						open={openPicker}
						autoFocus={true}
						style={{ width: "100%", fontWeight: "bold" }}
						onClick={() => setOpenPicker(true)}
						onChange={(value: any, dateString: any) => {
							handleSearchEnter(value, "date");
						}}
						onBlur={() => {
							handleClearSearch(dataIndex);
						}}
						renderExtraFooter={datePickerCustomButton}
						showToday={false}
					/>
				);
			}
		}

		if (!enableSearch) {
			return title;
		} else {
			return (
				<Button
					onClick={(e: any) => {
						e.stopPropagation();
						handleSearchModeToggle(dataIndex);
					}}
					type="link"
					style={{ color: "#000000", fontWeight: "bold" }}
				>
					<Space>
						{title}
						<SearchOutlined />
					</Space>
				</Button>
			);
		}
	};

	const transformedColumns = columns.map((column: any) => ({
		...column,
		...(column.sorter ? { sorter: handleSort(column) } : {}),
		title: getColumnHeader(
			column,
			column.dataIndex in searchModes,
			handleSearchModeToggle
		),
	}));

	return { transformedColumns, filteredData };
};
