import React, { useEffect, useState, useRef, useMemo } from "react";
import {
	Button,
	Modal,
	Space,
	Form,
	Input,
	Select,
	Row,
	Col,
	Empty,
	message,
	Tag,
	Tooltip,
	Spin,
	Divider,
} from "antd";
import { InputRef, notification } from "antd";
import {
	EditOutlined,
	SelectOutlined,
	PlusOutlined,
	CheckOutlined,
	CloseCircleOutlined,
	PlusCircleOutlined,
} from "@ant-design/icons";
import isEqual from "lodash/isEqual";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../assets/css/EditorialModal.css";
import cheerio from "cheerio";
import ManageModal from "./Manage_Tags_Categories_Modal";

let tempImagesModalState = false;
const Editorial = (props: any) => {
	// Type of article
	const type = "type" in props.data ? props.data.type : null;

	// Variables for modal
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [modalLoading, setModalLoading] = useState<boolean>(false);
	const [modalSaveDisable, setModalSaveDisable] = useState<boolean>(true);
	const [alert_Message_Unsaved, set_Alert_Message_Unsaved] =
		useState<boolean>(false);

	// Variables for title
	const [newTitle, setNewTitle] = useState<any>("");
	const [titleEmptyState, setTitleEmptyState] = useState<boolean>(false);

	// Variable for story page url
	const [storyPageUrl, setStoryPageUrl] = useState<any>("");

	// Variable for newswire url
	const [newswireUrl, setNewswireUrl] = useState<any>([]);
	const [newNewswireUrlInputValue, setNewNewswireUrlInputValue] =
		useState<any>("");
	const [duplicateUrl, setDuplicateUrl] = useState<boolean>(false);

	// Variables for author
	const [newAuthor, setNewAuthor] = useState<any>([]);
	const [authorEmptyState, setAuthorEmptyState] = useState<boolean>(false);
	const [inputVisible, setInputVisible] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [editInputIndex, setEditInputIndex] = useState(-1);
	const [editInputValue, setEditInputValue] = useState("");
	const inputRef = useRef<InputRef>(null);
	const editInputRef = useRef<InputRef>(null);

	// Variable for sender
	const [newSender, setNewSender] = useState<any>("");
	const [senderEmptyState, setSenderEmptyState] = useState<boolean>(false);

	// Variable for mailbox
	const [newMailbox, setNewMailbox] = useState<any>("");
	const [mailboxEmptyState, setMailboxEmptyState] = useState<boolean>(false);

	// Variables for categories
	// const [id, setId] = useState<any>("")
	const [allCategories, setAllCategories] = useState<any>([]);
	// const [tempAllCategories, setTempAllCategories] = useState<any>([]);
	const [newCategories, setNewCategories] = useState<any>([]);
	const [categoriesEmptyState, setCategoriesEmptyState] =
		useState<boolean>(false);
	const [categoriesOption, setCategoriesOption] = useState<any>([]);

	// Variables for tags
	const [allTags, setAllTags] = useState<any>([]);
	const [newTags, setNewTags] = useState<any>();
	const [tagsOption, setTagsOption] = useState<any>([]);
	const [tagEmptyState, setTagEmptyState] = useState<boolean>(false);

	// Variables for images
	const [newImages, setNewImages] = useState<any>([]);

	// Variables for feature image
	const [newFeauteImage, setNewFeatureImage] = useState<any>([]);

	// Variables for content
	const [newContent, setNewContent] = useState<any>("");
	const [contentEmptyState, setContentEmptyState] = useState<boolean>(false);
	const [tempNewContent, setTempNewContent] = useState<any>();
	const [quillBoxState, setQuillBoxState] = useState<any>(true);
	const [initializeQuillContentState, setInitializeQuillContentState] =
		useState<any>(true);
	const [imagesModalOpen, setImagesModalOpen] = useState<boolean>(false);

	// Variable to classify the newswire article
	const [newswireArticleState, setNewswireArticleState] =
		useState<boolean>(false);

	// notification config
	notification.config({
		placement: "bottomRight",
		bottom: 50,
		duration: 3000,
	});

	// ********************************************************************************************* //
	// Handle initialize the data
	// ********************************************************************************************* //
	//Format title
	const formatTitle = (data: string) => {
		if (data != undefined && data !== null) {
			return data;
		}
		return "";
	};
	// Format url
	const formatUrl = (data: any) => {
		if (data != undefined && data != null) {
			return data;
		}
		return null;
	};
	// Format published date format
	const formatDate = (data: any) => {
		if (data !== undefined && data !== null && data !== "No Date") {
			var d = new Date(data);
			var options: any = {
				day: "2-digit",
				month: "2-digit",
				year: "numeric",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				hour12: true,
				hourCycle: "h12",
			};
			var formatter = new Intl.DateTimeFormat("en-GB", options);
			var formattedDate = formatter.format(d);
			formattedDate = formattedDate
				.replace("am", "AM")
				.replace("pm", "PM");
			return formattedDate;
		}
		return "No Date Found";
	};
	// Format publisher
	const formatPublisher = (data: string) => {
		if (data != undefined && data !== null) {
			return data;
		}
		return "Publisher Not Found";
	};
	// Format author
	const formatAuthor = (data: any[]) => {
		if (data != undefined && data != null && data.length !== 0) {
			return data;
		}
		return [];
	};
	// Format sender
	const formatSender = (data: string) => {
		if (data != undefined && data !== null) {
			return data;
		}
		return "Sender not found";
	};
	// Format mailbox
	const formatMailbox = (data: string) => {
		if (data != undefined && data !== null) {
			return data;
		}
		return "Mailbox not found";
	};
	// Format categories
	const formatCategories = (data: any[]) => {
		if (
			data != undefined &&
			data != null &&
			data.length != 0 &&
			allCategories.length != 0
		) {
			let tempData: any = [];
			for (let x = 0; x < allCategories.length; x++) {
				for (let y = 0; y < data.length; y++) {
					if (allCategories[x].value === data[y].referenceId) {
						tempData.push(data[y].referenceId);
					}
				}
			}
			return tempData;
		}
		return [];
	};
	// Format tags
	const formatTags = (data: any[]) => {
		if (
			data != undefined &&
			data != null &&
			data.length != 0 &&
			allTags.length != 0
		) {
			let tempData: any = [];
			for (let x = 0; x < allTags.length; x++) {
				for (let y = 0; y < data.length; y++) {
					if (allTags[x].value === data[y].referenceId) {
						tempData.push(data[y].referenceId);
					}
				}
			}
			return tempData;
		}
		return [];
	};
	// Format images
	const formatImages = (data: any) => {
		if (data != undefined && data != null && data.length != 0) {
			// // Save the feature image
			if (newFeauteImage.length === 0) {
				const tempFeatureImage = data.filter(
					(item: any) => item.selected === true
				);
				setNewFeatureImage(tempFeatureImage);
			}

			// Splie images into rows
			let tempData: any = [];
			for (let i = 0; i < data.length; i += 3) {
				const row = data.slice(i, i + 3);
				tempData.push(row);
			}
			return tempData;
		}
		return [];
	};
	// Format content
	const formatContent = (data: any) => {
		if (data != undefined && data != null) {
			return data;
		}
		return "";
	};
	// Initial data used to compare the changes
	const functionInitializeData = () => {
		const initialData = {
			title: formatTitle(props.data.title),
			url: formatUrl(props.data.url),
			publish_date: formatDate(props.data.publish_date),
			receive_date: formatDate(props.data.receive_date),
			publisher: formatPublisher(props.data.publisher),
			author: formatAuthor(props.data.author),
			sender: formatSender(props.data.sender),
			mailbox: formatMailbox(props.data.mailbox),
			categories: formatCategories(props.data.categories),
			tags: formatTags(props.data.tags),
			images: formatImages(props.data.images),
			content: formatContent(props.data.contents),
		};
		return initialData;
	};
	const firstData: any = functionInitializeData();
	// Copy the initial data to useState (For display original data)
	const copyData = () => {
		setNewTitle(firstData.title);
		if (newswireArticleState === false) {
			setStoryPageUrl(firstData.url);
			setNewswireUrl(null);
		} else {
			setStoryPageUrl(null);
			setNewswireUrl(firstData.url);
		}
		setNewAuthor(firstData.author);
		setNewSender(firstData.sender);
		setNewMailbox(firstData.mailbox);
		setNewCategories(firstData.categories);
		setNewTags(firstData.tags);
		setNewImages(firstData.images);
		setNewContent(firstData.content);
		setTempNewContent(firstData.content);
	};

	useEffect(() => {
		setModalOpen(props.open);
		setModalLoading(true);
		setNewswireArticleState("mailbox" in props.data);
		fetchTags();
		fetchCategories();
	}, []);

	useEffect(() => {
		if (modalLoading === false) {
			copyData();
		}
	}, [modalLoading]);

	// Fetch all and tags
	const fetchTags = () => {
		setModalLoading(true);
		fetch(`/api/all_tags/`)
			.then((res) => res.json())
			.then((res) => {
				let data = [];
				if (res.data != null) {
					data = res.data.map((req: any) => ({
						_id: req._id,
						id: req.id,
						name: req.name,
						disable: req.disable === undefined ? null : req.disable,
					}));
				}
				return data;
			})
			.then((data) => {
				const tags = data
					.map((tag: any) => ({
						value: tag._id,
						label: tag.name,
						id: tag.id,
						disable: tag.disable,
					}))
					.sort((a: any, b: any) => a.label.localeCompare(b.label));
				setAllTags(tags);
				setTagsOption(tags);
			});
	};
	// Fetch all and categories
	const fetchCategories = () => {
		fetch(`/api/all_categories/`)
			.then((res) => res.json())
			.then((res) => {
				let data = [];
				if (res.data != null) {
					data = res.data.map((req: any) => ({
						_id: req._id,
						id: req.id,
						name: req.name,
						disable: req.disable === undefined ? null : req.disable,
					}));
				}
				return data;
			})
			.then((data) => {
				const categories = data
					.map((categories: any) => ({
						value: categories._id,
						label: categories.name,
						id: categories.id,
						disable: categories.disable,
					}))
					.sort((a: any, b: any) => a.label.localeCompare(b.label));

				setAllCategories(categories);
				setCategoriesOption(categories);
				setModalLoading(false);
			});
	};

	// ********************************************************************************************* //
	// Handle empty input field
	// ********************************************************************************************* //
	// Function detect empty input form
	const detectEmptyInput = () => {
		let emptyState;

		// Check title empty input
		if (/^\s*$/.test(newTitle) === true) {
			setTitleEmptyState(true);
			emptyState = true;
		} else {
			setTitleEmptyState(false);
		}

		if (newswireArticleState === false) {
			// Check author empty input
			if (newAuthor.length === 0) {
				setAuthorEmptyState(true);
				emptyState = true;
			} else {
				setAuthorEmptyState(false);
			}

			// Check categories empty input
			if (newCategories.length === 0) {
				setCategoriesEmptyState(true);
				emptyState = true;
			} else {
				setCategoriesEmptyState(false);
			}

			// Check tags empty input
			if (newTags.length === 0) {
				setTagEmptyState(true);
				emptyState = true;
			} else {
				setTagEmptyState(false);
			}
		}

		if (newswireArticleState === true) {
			if (newswireUrl !== null) {
				// Check newswire url input format
				for (let x = 0; x < newswireUrl.length; x++) {
					if (/^\s*$/.test(newswireUrl[x]) === true) {
						emptyState = true;
						break;
					} else if (checkUrlFormat(newswireUrl[x]) === false) {
						emptyState = true;
						break;
					}
				}
				// Check url duplicate dataset
				let uniqueChars: any = new Set(newswireUrl);
				if (newswireUrl.length > uniqueChars.size) {
					// console.log("Test1= ",newswireUrl.length)
					// console.log("Test2= ",uniqueChars.size)
					setDuplicateUrl(true);
					emptyState = true;
				} else {
					setDuplicateUrl(false);
				}
			}
			// Check sender empty input
			if (/^\s*$/.test(newSender) === true) {
				setSenderEmptyState(true);
				emptyState = true;
			} else {
				setSenderEmptyState(false);
			}
			// Check mailbox empty input
			if (/^\s*$/.test(newMailbox) === true) {
				setMailboxEmptyState(true);
				emptyState = true;
			} else {
				setMailboxEmptyState(false);
			}
		}

		// Check content empty input
		const $ = cheerio.load(newContent);
		const content_text = $("body").text();
		if (content_text.length > 0) {
			setContentEmptyState(false);
		} else {
			setContentEmptyState(true);
			emptyState = true;
		}

		if (emptyState === true) {
			return true;
		}
		return false;
	};

	// ********************************************************************************************* //
	// Handle input changes field
	// ********************************************************************************************* //
	// Function detect changes made on the modal
	const detectModalChanges = () => {
		if (detectEmptyInput() === false) {
			if (
				newTitle !== firstData.title ||
				isEqual(newContent, tempNewContent) === false
			) {
				return true;
			}

			if (newswireArticleState === false) {
				// Check changes in featue image
				if (newImages.length !== 0) {
					const tempImages: any = [].concat(...newImages);
					for (let x = 0; x < tempImages.length; x++) {
						if (tempImages[x].selected === true) {
							if (tempImages[x]._id !== newFeauteImage[0]._id) {
								return true;
							}
						}
					}
				}
				if (
					isEqual(newAuthor, firstData.author) === false ||
					isEqual(newCategories, firstData.categories) === false ||
					isEqual(newTags, firstData.tags) === false
				) {
					return true;
				}
			}

			if (newswireArticleState === true) {
				// Check changes in newswire url
				if (
					isEqual(newswireUrl, firstData.url) === false ||
					newSender !== firstData.sender ||
					newMailbox !== firstData.mailbox
				) {
					return true;
				}
			}

			return false;
		}
		return false;
	};
	// Handle modal save button (Only when changes make)
	useEffect(() => {
		if (modalOpen === true) {
			if (detectModalChanges() === true) {
				setModalSaveDisable(false);
			} else {
				setModalSaveDisable(true);
			}
		}
	}, [
		newTitle,
		newswireUrl,
		newAuthor,
		newSender,
		newMailbox,
		newCategories,
		newTags,
		newImages,
		newContent,
	]);

	// ********************************************************************************************* //
	// Handle action before modal close
	// ********************************************************************************************* //
	// Handle modal pop out
	const handleModalClose = (data: any) => {
		if (data === "save" && modalSaveDisable === true) {
			return;
		}
		// Promp pop out message if data changed
		if (detectEmptyInput() === true || detectModalChanges() === true) {
			console.log(data);
			if (data === "cancel") {
				set_Alert_Message_Unsaved(true);
			}
			if (data === "save") {
				if (checkDataToUpdate() === true) {
					// set_Alert_Message_Save(true);
					message.success(
						<span style={{ fontSize: "15px" }}>
							Record is Saved
							<hr></hr>
							Changes made has successfully been updated
						</span>
					);

					setModalOpen(false);
					if (
						props.handleCancel &&
						typeof props.handleCancel === "function"
					) {
						props.handleCancel();
					}
				} else {
					message.error("Error Save Data!", 2000);
					setModalOpen(false);
					if (
						props.handleCancel &&
						typeof props.handleCancel === "function"
					) {
						props.handleCancel();
					}
				}
			}
		} else {
			setModalOpen(false);
			if (
				props.handleCancel &&
				typeof props.handleCancel === "function"
			) {
				props.handleCancel();
			}
		}
	};
	// Handle pop out message (Unchange message && Saved message)
	const handleAlertClose = (data: any) => {
		// Leave without save
		if (data === "leave") {
			set_Alert_Message_Unsaved(false);
			setModalOpen(false);
			if (
				props.handleCancel &&
				typeof props.handleCancel === "function"
			) {
				props.handleCancel();
			}
		}
		// Stay of modal
		else if (data === "stay") {
			set_Alert_Message_Unsaved(false);
		}
	};

	// ********************************************************************************************* //
	// Handle small components
	// ********************************************************************************************* //
	// Show the modal name
	const showHeader = () => {
		return (
			<Space>
				<EditOutlined />
				<div>Edit</div>
			</Space>
		);
	};
	// Open title url (Direct to article)
	const openWindow = (url: any) => {
		window.open(url, "newwindow", "width=600,height=400");
		return false;
	};

	// ********************************************************************************************* //
	// Handle update to database field
	// ********************************************************************************************* //
	//Check data to update
	const checkDataToUpdate = () => {
		// Update title
		if (newTitle !== firstData.title) {
			if (newswireArticleState === true) {
				updateDatabase(newTitle, "title", "newswire");
			} else {
				updateDatabase(newTitle, "title", "website_news");
			}
		}

		if (newswireArticleState === false) {
			// Update author
			if (isEqual(newAuthor, firstData.author) === false) {
				updateDatabase(newAuthor, "author", "website_news");
			}
			// Update categories
			if (isEqual(newCategories, firstData.categories) === false) {
				let tempData: any[] = [];
				for (let x = 0; x < newCategories.length; x++) {
					for (let y = 0; y < allCategories.length; y++) {
						if (allCategories[y].value === newCategories[x]) {
							tempData[x] = {
								referenceId: allCategories[y].value,
								name: allCategories[y].label,
								id: allCategories[y].id,
							};
						}
					}
				}
				updateDatabase(tempData, "categories", "website_news");
			}
			// Update tags
			if (isEqual(newTags, firstData.tags) === false) {
				let tempData: any[] = [];
				for (let x = 0; x < newTags.length; x++) {
					for (let y = 0; y < allTags.length; y++) {
						if (allTags[y].value === newTags[x]) {
							tempData[x] = {
								referenceId: allTags[y].value,
								name: allTags[y].label,
								id: allTags[y].id,
							};
						}
					}
				}
				updateDatabase(tempData, "tags", "website_news");
			}
			// Update feature image (Using newImages data)
			if (newImages.length !== 0) {
				const tempImages: any = [].concat(...newImages);
				for (let x = 0; x < tempImages.length; x++) {
					if (tempImages[x].selected === true) {
						if (tempImages[x]._id !== newFeauteImage[0]._id) {
							if ("mailbox" in props.data === false) {
								updateDatabase(
									tempImages,
									"images",
									"website_news"
								);
								const newData = {
									src: tempImages[x].src,
									id: null,
								};
								updateDatabase(
									newData,
									"feature_image",
									"website_news"
								);
							}
							break;
						}
					}
				}
			}
		}

		if (newswireArticleState === true) {
			// Update newswire url
			if (isEqual(newswireUrl, firstData.url) === false) {
				updateDatabase(newswireUrl, "url", "newswire");
			}
			// Update sender
			if (newSender !== firstData.sender) {
				updateDatabase(newSender, "sender", "newswire");
			}
			// Update mailbox
			if (newMailbox !== firstData.mailbox) {
				updateDatabase(newMailbox, "mailbox", "newswire");
			}
		}

		// Update contents
		if (isEqual(newContent, tempNewContent) === false) {
			// Load HTML content into Cheerio
			// const $ = cheerio.load(newContent);

			// Remove styling from <img> tags
			// $('img').removeAttr('style');

			// Remove <head> tags
			// $('head').remove();

			// Replace body with it's child
			// const bodyChildren = $('body').children();

			// let modifiedHtml = '';

			// bodyChildren.each((_index, element) => {
			// 		const pTag = $(element);
			// 		const imgTag = pTag.children('img');
			// 		if (imgTag.length > 0) {
			// 			// Find all elements with 'contenteditable="true"' attribute
			// 			$('[contenteditable="true"]').removeAttr('contenteditable');

			// 			const figureTag = $(`<figure>${imgTag}</figure>`);
			// 			modifiedHtml += $.html(figureTag);
			// 		}
			// 		else{
			// 			modifiedHtml += $.html(element);
			// 		}

			// });
			if (newswireArticleState === true) {
				updateDatabase(newContent, "contents", "newswire");
			} else {
				updateDatabase(newContent, "contents", "website_news");
			}
		}
		return true;
	};

	//Update to database
	const updateDatabase = (newData: any, name: any, type: string) => {
		return new Promise((resolve: any, reject: any) => {
			let stat: any = {};
			stat[name] = newData;

			axios
				.put(
					`/api/${type}/${props.data.key}`,
					stat
				)
				.then((res: any) => {
					resolve(res.data ? res.data : []);
				})
				.catch((err: any) => {
					console.error(err);
					reject(err);
				});
		});
	};

	// ********************************************************************************************* //
	// Handle newswire url field
	// ********************************************************************************************* //
	// Handle verify urls format
	const checkUrlFormat = (data: string) => {
		var pattern = new RegExp(
			"^(https?:\\/\\/)?" + // protocol
				"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
				"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
				"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
				"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
				"(\\#[-a-z\\d_]*)?$",
			"i"
		);
		if (pattern.test(data)) {
			return true;
		} else {
			return false;
		}
	};
	// Handle add new url for newswire
	const handleNewNewswireUrlInputConfirm = () => {
		if (/^\s*$/.test(newNewswireUrlInputValue) === true) {
			notification.destroy();
			notification.warning({
				message: "Empty Input!",
				description: `Cannot add empty newswire url`,
			});
		} else if (checkUrlFormat(newNewswireUrlInputValue) === false) {
			notification.destroy();
			notification.warning({
				message: "Invalid input!",
				description: "Please enter proper URL.",
			});
		} else if (newswireUrl !== null) {
			const existingData = newswireUrl.filter(
				(item: any) => item === newNewswireUrlInputValue
			);
			if (existingData.length !== 0) {
				notification.destroy();
				notification.info({
					message: "Existing Newswire Url!",
					description: `'${newNewswireUrlInputValue}' is in the data list`,
				});
			} else {
				setNewswireUrl((previous: any) => [
					...previous,
					newNewswireUrlInputValue,
				]);
				setNewNewswireUrlInputValue("");
			}
		} else {
			setNewswireUrl([newNewswireUrlInputValue]);
			setNewNewswireUrlInputValue("");
		}
	};
	// Handle edit existing url for newswire
	const handleEditNewswireUrl = (e: any, index: any) => {
		let tempData: any = [];
		for (let x = 0; x < newswireUrl.length; x++) {
			if (x === index && newswireUrl[x] !== e.target.value) {
				tempData.push(e.target.value);
			} else {
				tempData.push(newswireUrl[x]);
			}
		}
		setNewswireUrl(tempData);
	};

	// ********************************************************************************************* //
	// Handle author field
	// ********************************************************************************************* //
	useEffect(() => {
		if (inputVisible) {
			inputRef.current?.focus();
		}
	}, [inputVisible]);

	useEffect(() => {
		editInputRef.current?.focus();
	}, [inputValue]);

	const handleClose = (removedTag: string) => {
		const newTags = newAuthor.filter((tag: any) => tag !== removedTag);
		setNewAuthor(newTags);
	};

	const showInput = () => {
		setInputVisible(true);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const handleInputConfirm = () => {
		if (inputValue && newAuthor.indexOf(inputValue) === -1) {
			setNewAuthor([inputValue, ...newAuthor]);
			setInputVisible(false);
			setInputValue("");
		} else if (newAuthor.indexOf(inputValue) !== -1) {
			notification.info({
				message: "Existing author!",
				description: `${inputValue} is in the author list`,
			});
		} else {
			setInputVisible(false);
		}
	};

	const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEditInputValue(e.target.value);
	};

	const handleEditInputConfirm = () => {
		const newTags = [...newAuthor];
		newTags[editInputIndex] = editInputValue;
		setNewAuthor(newTags);
		setEditInputIndex(-1);
		setInputValue("");
	};

	// ********************************************************************************************* //
	// Handle feature image field
	// ********************************************************************************************* //
	const handleSelectFeatureImage = (row: any, col: any) => {
		// Create a copy of the array
		const tempData = [...newImages];

		for (let y = 0; y < tempData.length; y++) {
			for (let x = 0; x < tempData[y].length; x++) {
				// Update selected property in the copied array
				tempData[y][x] = { ...tempData[y][x], selected: false };
			}
		}

		// Update selected property for the specific image
		tempData[row][col] = { ...tempData[row][col], selected: true };

		// Update the state with the new copied array
		setNewImages(tempData);
	};

	// ********************************************************************************************* //
	// Handle image modal action field
	// ********************************************************************************************* //
	// Handle dragable images modal
	const element = document.getElementById("imagesModal");
	if (element != null) {
		dragElement(element);
	}
	function dragElement(elmnt: any) {
		var pos1 = 0,
			pos2 = 0,
			pos3 = 0,
			pos4 = 0;
		if (document.getElementById(elmnt.id + "Title")) {
			// if present, the header is where you move the DIV from:
			document.getElementById(elmnt.id + "Title")!.onmousedown =
				dragMouseDown;
		} else {
			// otherwise, move the DIV from anywhere inside the DIV:
			elmnt.onmousedown = dragMouseDown;
		}

		function dragMouseDown(e: any) {
			e = e || window.event;
			e.preventDefault();
			// get the mouse cursor position at startup:
			pos3 = e.clientX;
			pos4 = e.clientY;
			document.onmouseup = closeDragElement;
			// call a function whenever the cursor moves:
			document.onmousemove = elementDrag;
		}

		function elementDrag(e: any) {
			e = e || window.event;
			e.preventDefault();
			// calculate the new cursor position:
			pos1 = pos3 - e.clientX;
			pos2 = pos4 - e.clientY;
			pos3 = e.clientX;
			pos4 = e.clientY;
			// set the element's new position:
			elmnt.style.top = elmnt.offsetTop - pos2 + "px";
			elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
		}

		function closeDragElement() {
			// stop moving when mouse button is released:
			document.onmouseup = null;
			document.onmousemove = null;
		}
	}
	// Handle insert image to text editor by clicking the images
	const editorRef = useRef<any | null>(null);
	const insertImages = (imageUrl: string) => {
		if (editorRef.current) {
			const editor = editorRef.current.getEditor();
			const range = editor.getSelection();
			const index = range ? range.index : 0;

			const imageElement = `<img src="${imageUrl}" alt="Image" />`;
			editor.clipboard.dangerouslyPasteHTML(index, imageElement);
		}
	};

	// ********************************************************************************************* //
	// Handle content field
	// ********************************************************************************************* //
	// Handle text editor custom image button state
	const handleImagesModal = () => {
		setImagesModalOpen(!tempImagesModalState);
		tempImagesModalState = !tempImagesModalState;
	};
	// Rich text editor toolbar setting
	const icon = ReactQuill.Quill.import("ui/icons");
	icon[
		"undo"
	] = `<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon><path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>`;
	icon[
		"redo"
	] = `<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon><path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>`;
	icon[
		"customButton"
	] = `<svg viewBox="0 0 18 18"> <rect class="ql-stroke" height="10" width="12" x="3" y="4"></rect> <circle class="ql-fill" cx="6" cy="7" r="1"></circle> <polyline class="ql-even ql-fill" points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"></polyline> </svg>`;
	const modules = useMemo(
		() => ({
			history: {
				delay: 0,
				maxStack: 1000,
				userOnly: true,
			},
			toolbar: {
				container: [
					["undo", "redo"],
					[{ font: [] }],
					[{ header: [1, 2, 3, 4, 5, 6, false] }],
					["bold", "italic", "underline", "strike"],
					[{ color: [] }, { background: [] }],
					[{ script: "sub" }, { script: "super" }],
					[{ list: "ordered" }, { list: "bullet" }],
					[{ align: [] }],
					[
						newswireArticleState === false
							? "customButton"
							: "images",
					],
					// ["image"],
				],
				handlers: {
					customButton: handleImagesModal,
					undo: () => editorRef.current.editor.history.undo(),
					redo: () => editorRef.current.editor.history.redo(),
				},
			},
			clipboard: {
				matchVisual: false, // Disable matching clipboard content to Quill's visual format
			},
		}),
		[newswireArticleState]
	);
	// Handle rich text editor input(Detect the changes make on content)
	const handleOnchange = (content: any, delta: any, source: any) => {
		if (initializeQuillContentState === true) {
			setNewContent(content);
			setTempNewContent(content);
		}
		if (initializeQuillContentState === false) {
			setNewContent(content);
		}
	};
	// Handle Image resize in the content
	const handleImageClick = (event: any) => {
		setQuillBoxState(false);
		setInitializeQuillContentState(false);
		const target = event.target as HTMLElement;
		if (target.className == "featureImage") {
			target.classList.remove(target.className);
		}
		if (target.tagName === "IMG") {
			const imgElement = target as HTMLImageElement;
			enableImageResizing(imgElement);
		}
	};
	function enableImageResizing(img: HTMLImageElement) {
		img.contentEditable = "true";
		img.style.resize = "both";
		img.style.overflow = "auto";
		img.style.position = "relative";
		img.style.border = "1px dashed black";
		img.style.cursor = "se-resize";
		img.style.padding = "5px";
		let initialX = 0;
		let initialY = 0;
		let initialWidth = 0;
		let initialHeight = 0;
		let isDragging = false;
		img.addEventListener("mousedown", (e) => {
			initialX = e.clientX;
			initialY = e.clientY;
			initialWidth = img.clientWidth;
			initialHeight = img.clientHeight;
			isDragging = true;
		});
		document.addEventListener("mousemove", (e) => {
			if (isDragging) {
				handleDrag(
					e,
					img,
					initialX,
					initialY,
					initialWidth,
					initialHeight
				);
			}
		});
		document.addEventListener("mouseup", (e) => {
			img.style.border = "0px solid black";
			isDragging = false;
		});
		document.addEventListener("keydown", (e) => {
			if (e.key == "Delete" || e.key == "Backspace") {
				img.remove();
			}
		});
	}
	function handleDrag(
		e: MouseEvent,
		img: HTMLImageElement,
		initialX: number,
		initialY: number,
		initialWidth: number,
		initialHeight: number
	) {
		e.preventDefault();
		const dx = e.clientX - initialX;
		const dy = e.clientY - initialY;
		const newWidth = initialWidth + dx;
		const newHeight = initialHeight + dy;
		resizeImage(img, newWidth, newHeight);
	}
	function resizeImage(img: HTMLImageElement, width: number, height: number) {
		img.width = width;
		img.height = height;
	}

	// Categories and tags manage modal
	const [categoriesModalOpen, setCategoriesModalOpen] =
		useState<boolean>(false);
	const [tagsModalOpen, setTagsModalOpen] = useState<boolean>(false);
	const handleCategoriesModalCancel = () => {
		setCategoriesModalOpen(!categoriesModalOpen);
	};
	const handleTagsModalCancel = () => {
		setTagsModalOpen(!tagsModalOpen);
	};

	useEffect(() => {
		if (
			allCategories != undefined ||
			allCategories != null ||
			allCategories.length != 0
		) {
			const selected = allCategories.filter((item: any) =>
				newCategories.includes(item.value)
			);
			const remaining = allCategories.filter(
				(item: any) => !newCategories.includes(item.value)
			);
			setCategoriesOption([...selected, ...remaining]);
		}
	}, [allCategories, newCategories]);

	useEffect(() => {
		if (allTags != undefined || allTags != null || allTags.length != 0) {
			const selected = allTags.filter((item: any) =>
				newTags.includes(item.value)
			);
			const remaining = allTags.filter(
				(item: any) => !newTags.includes(item.value)
			);
			setTagsOption([...selected, ...remaining]);
		}
	}, [allTags, newTags]);

	return (
		<>
			{/* Alert Message For Prop To Save Or Leave */}
			{alert_Message_Unsaved && (
				<Modal
					width={"30%"}
					title="Leave Without saving?"
					open={alert_Message_Unsaved}
					onOk={() => handleAlertClose("stay")}
					onCancel={() => handleAlertClose("stay")}
					className="rounded-modal headermissing"
					footer={[
						<Button
							key="stay"
							type="primary"
							onClick={() => handleAlertClose("stay")}
						>
							Stay On This Page
						</Button>,
						<Button
							className="custom-button"
							key="leave"
							style={{ float: "left" }}
							onClick={() => handleAlertClose("leave")}
						>
							Leave
						</Button>,
					]}
				>
					<div
						className="modal-content"
						style={{ textAlign: "center" }}
					>
						<div style={{ fontWeight: "bold", fontSize: "16px" }}>
							Leave Without Saving?
						</div>
						<hr />
						<div
							style={{
								lineHeight: "1",
								padding: "24px 10px 0px 10px",
								fontSize: "14px",
							}}
						>
							<p>
								You have unsaved changes that will be lost if
								you leave
							</p>
							<p className="important-note">
								Important note: Data is{" "}
								<span style={{ fontWeight: "600" }}>NOT</span>{" "}
								auto-updated
							</p>
						</div>
					</div>
				</Modal>
			)}

			{/* Editorial modal */}
			<Modal
				className="modalContainer"
				width={"70%"}
				title={showHeader()}
				open={modalOpen}
				closable={true}
				onCancel={() => handleModalClose("cancel")}
				footer={
					<div style={{ marginTop: "1%" }}>
						<Button
							className="button-modal-cancel"
							onClick={() => handleModalClose("cancel")}
						>
							Cancel
						</Button>
						<Button
							className={`button-modal-save${
								modalSaveDisable === true ? "-true" : "-false"
							}`}
							onClick={() => handleModalClose("save")}
						>
							Save
						</Button>
					</div>
				}
			>
				{modalLoading ? (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							height: "75vh",
						}}
					>
						<Spin size="large" />
					</div>
				) : (
					<Form
						layout="horizontal"
						labelCol={{ span: 3 }}
						wrapperCol={{ flex: 1 }}
						className="formContainer"
					>
						<Form.Item>
							<label className="titleLabel">Title</label>
							<div className="titleInputContainer">
								{storyPageUrl !== null ? (
									<>
										<Input
											className={`titleInput${
												titleEmptyState === true
													? "-true"
													: ""
											}`}
											style={{ width: "94%" }}
											placeholder="Please enter the title..."
											value={newTitle}
											onChange={(e: any) =>
												setNewTitle(e.target.value)
											}
										/>
										<Button
											style={{
												width: "6%",
												color: "#1890FF",
												transition:
													"all 0.2s ease-in-out",
											}}
											onClick={() =>
												openWindow(storyPageUrl)
											}
										>
											<SelectOutlined
												style={{
													transform: "scaleX(-1)",
													fontSize: "100%",
												}}
											/>
										</Button>
									</>
								) : (
									<Input
										className={`titleInput${
											titleEmptyState === true
												? "-true"
												: ""
										}`}
										style={{ width: "100%" }}
										placeholder="Please enter the title..."
										value={newTitle}
										onChange={(e: any) =>
											setNewTitle(e.target.value)
										}
									/>
								)}
								{titleEmptyState === true ? (
									<p
										style={{
											color: "tomato",
											marginLeft: "0.5%",
										}}
									>
										Title cannot be empty!
									</p>
								) : null}
							</div>
						</Form.Item>

						{newswireArticleState === true ? (
							<Form.Item>
								<label className="newswireUrlLabel">
									Source
								</label>
								<div
									className="newswireUrlInputContainer"
									style={{
										maxHeight: "25vh",
										overflowY: "auto",
									}}
								>
									{newswireUrl !== null
										? newswireUrl.map(
												(data: any, index: number) => {
													return (
														<div
															key={index}
															style={{
																margin: "2%",
															}}
														>
															<div
																style={{
																	display:
																		"flex",
																	justifyContent:
																		"center",
																	alignItems:
																		"center",
																}}
															>
																<Input
																	className={`newsUrlInput-${
																		newswireUrl.filter(
																			(
																				item: any
																			) =>
																				item ===
																				data
																		)
																			.length >
																		1
																			? false
																			: checkUrlFormat(
																					data
																			  )
																	}`}
																	key={index}
																	type="text"
																	placeholder="Edit Url..."
																	style={{
																		width: "100%",
																	}}
																	allowClear
																	value={data}
																	onChange={(
																		e: any
																	) =>
																		handleEditNewswireUrl(
																			e,
																			index
																		)
																	}
																/>
																<Button
																	style={{
																		width: "6%",
																		color: "#1890FF",
																		transition:
																			"all 0.2s ease-in-out",
																	}}
																	onClick={() =>
																		openWindow(
																			data
																		)
																	}
																>
																	<SelectOutlined
																		style={{
																			transform:
																				"scaleX(-1)",
																			fontSize:
																				"100%",
																		}}
																	/>
																</Button>
																<Button
																	className="button-delete-source"
																	onClick={() =>
																		setNewswireUrl(
																			newswireUrl.filter(
																				(
																					item: any,
																					position: number
																				) =>
																					position !==
																					index
																			)
																		)
																	}
																>
																	<CloseCircleOutlined />
																</Button>
															</div>
															{/^\s*$/.test(
																data
															) === true ? (
																<p
																	style={{
																		color: "tomato",
																	}}
																>
																	Url input
																	cannot be
																	empty
																</p>
															) : checkUrlFormat(
																	data
															  ) === false ? (
																<p
																	style={{
																		color: "tomato",
																	}}
																>
																	Url format
																	not valid
																</p>
															) : null}
														</div>
													);
												}
										  )
										: null}
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											margin: "2%",
										}}
									>
										<Input
											type="text"
											placeholder="Add New Url..."
											style={{ width: "100%" }}
											value={newNewswireUrlInputValue}
											allowClear
											onChange={(e: any) =>
												setNewNewswireUrlInputValue(
													e.target.value
												)
											}
											onPressEnter={
												handleNewNewswireUrlInputConfirm
											}
										/>
										<Button
											onClick={
												handleNewNewswireUrlInputConfirm
											}
										>
											<PlusCircleOutlined />
										</Button>
									</div>
									{duplicateUrl == true ? (
										<p
											style={{
												color: "tomato",
												marginLeft: "2%",
											}}
										>
											There have duplicate urls
										</p>
									) : null}
								</div>
							</Form.Item>
						) : null}

						{newswireArticleState === false ? (
							<Form.Item>
								<label className="dateLabel">
									Published Date
								</label>
								<div className="dateInputContainer">
									<Input
										disabled
										value={firstData.publish_date}
										style={{ color: "grey" }}
									/>
								</div>
							</Form.Item>
						) : null}
						{newswireArticleState === true ? (
							<Form.Item>
								<label className="dateLabel">
									Receive Date
								</label>
								<div className="dateInputContainer">
									<Input
										disabled
										value={firstData.receive_date}
										style={{ color: "grey" }}
									/>
								</div>
							</Form.Item>
						) : null}

						{newswireArticleState === false ? (
							<Form.Item>
								<label className="publisherLabel">
									Publisher
								</label>
								<div className="publisherInputContainer">
									<Input
										value={firstData.publisher}
										disabled
										style={{ color: "grey" }}
									/>
								</div>
							</Form.Item>
						) : null}

						{newswireArticleState === false ? (
							<Form.Item>
								<label className="authorLabel">Author</label>
								<div className="authorInputContainer">
									<div
										className={`authorInput${
											authorEmptyState === true
												? "-true"
												: ""
										}`}
									>
										<Space
											style={{
												border: "1px solid #d9d9d9",
												padding: "4px 11px",
												width: "100%",
											}}
										>
											{newAuthor.map(
												(tag: any, index: any) => {
													if (
														editInputIndex === index
													) {
														return (
															<Input
																ref={
																	editInputRef
																}
																key={tag}
																size="small"
																className="tag-input"
																value={
																	editInputValue
																}
																onChange={
																	handleEditInputChange
																}
																onBlur={
																	handleEditInputConfirm
																}
																onPressEnter={
																	handleEditInputConfirm
																}
															/>
														);
													}

													const isLongTag =
														tag.length > 20;

													const tagElem = (
														<Tag
															className="edit-tag"
															style={{
																color:
																	index ===
																	newAuthor.length -
																		1
																		? "grey"
																		: "black",
															}}
															key={tag}
															// closable={index !== 0}
															closable={
																index !==
																newAuthor.length -
																	1
															}
															onClose={() =>
																handleClose(tag)
															}
														>
															<span
																onDoubleClick={(
																	e: any
																) => {
																	if (
																		index ===
																		newAuthor.length -
																			1
																	) {
																		message.destroy();
																		message.info(
																			<>
																				<p>
																					<span
																						style={{
																							fontWeight:
																								"bold",
																						}}
																					>
																						{
																							tag
																						}
																					</span>
																					<span>
																						{" "}
																						is
																						default
																						author.
																					</span>
																				</p>
																				<p>
																					Please
																					edit
																					it
																					in
																					publisher
																					configuration
																					page
																				</p>
																			</>
																		);
																		e.target.focus();
																	} else {
																		setEditInputIndex(
																			index
																		);
																		setEditInputValue(
																			tag
																		);
																		e.preventDefault();
																	}
																}}
															>
																{isLongTag
																	? `${tag.slice(
																			0,
																			20
																	  )}...`
																	: tag}
															</span>
														</Tag>
													);
													return isLongTag ? (
														<Tooltip
															title={tag}
															key={tag}
														>
															{tagElem}
														</Tooltip>
													) : (
														tagElem
													);
												}
											)}
											{inputVisible && (
												<Input
													ref={inputRef}
													type="text"
													size="small"
													className="tag-input"
													value={inputValue}
													onChange={handleInputChange}
													onBlur={handleInputConfirm}
													onPressEnter={
														handleInputConfirm
													}
												/>
											)}
											{!inputVisible && (
												<Tag
													className="site-tag-plus"
													onClick={showInput}
												>
													<PlusOutlined /> New Author
												</Tag>
											)}
										</Space>
									</div>
									{authorEmptyState === true ? (
										<p
											style={{
												color: "tomato",
												marginLeft: "0.5%",
											}}
										>
											Author cannot be empty!
										</p>
									) : null}
								</div>
							</Form.Item>
						) : null}

						{newswireArticleState === true ? (
							<Form.Item>
								<label className="senderLabel">Sender</label>
								<div className="senderInputContainer">
									<Input
										className={`senderInput${
											senderEmptyState === true
												? "-true"
												: ""
										}`}
										style={{ width: "100%" }}
										placeholder="Please enter the sender name..."
										value={newSender}
										onChange={(e: any) =>
											setNewSender(e.target.value)
										}
									/>
									{senderEmptyState === true ? (
										<p
											style={{
												color: "tomato",
												marginLeft: "0.5%",
											}}
										>
											Sender name cannot be empty!
										</p>
									) : null}
								</div>
							</Form.Item>
						) : null}

						{newswireArticleState === true ? (
							<Form.Item>
								<label className="mailboxLabel">Mailbox</label>
								<div className="mailboxInputContainer">
									<Input
										disabled
										className={`mailboxInput${
											mailboxEmptyState === true
												? "-true"
												: ""
										}`}
										style={{ width: "100%", color: "grey" }}
										placeholder="Please enter the mailbox..."
										value={
											newMailbox.charAt(0).toUpperCase() +
											newMailbox.slice(1)
										}
										onChange={(e: any) =>
											setNewMailbox(e.target.value)
										}
									/>
									{mailboxEmptyState === true ? (
										<p
											style={{
												color: "tomato",
												marginLeft: "0.5%",
											}}
										>
											Mailbox cannot be empty!
										</p>
									) : null}
								</div>
							</Form.Item>
						) : null}

						{newswireArticleState === false ? (
							<Form.Item>
								<label className="categoriesLabel">
									Categories
								</label>
								<Space
									className="categoriesInputContainer"
									direction="vertical"
								>
									<Select
										getPopupContainer={(trigger) =>
											trigger.parentElement
										}
										className={`categoriesInput${
											categoriesEmptyState === true
												? "-true"
												: ""
										}`}
										mode="multiple"
										style={{ width: "100%" }}
										placeholder="Please select"
										value={newCategories}
										options={categoriesOption}
										filterOption={(
											inputValue: string,
											option: any
										) =>
											option.label
												.toLowerCase()
												.includes(
													inputValue.toLowerCase()
												)
										}
										onChange={(selectedValues: any) =>
											setNewCategories([
												...selectedValues,
											])
										}
										dropdownStyle={{
											zIndex: "1000",
											transition: "max-height 0.01s",
										}}
										showArrow
										dropdownRender={(option: any) => (
											<>
												{option}
												<Divider
													style={{
														margin: "auto",
														borderTop:
															"1px solid grey",
													}}
												/>
												<Button
													className="manageCategoriesButton"
													type="text"
													onClick={() =>
														setCategoriesModalOpen(
															true
														)
													}
												>
													Manage categories
												</Button>
											</>
										)}
									/>
									{categoriesEmptyState === true ? (
										<p
											style={{
												color: "tomato",
												marginLeft: "0.5%",
											}}
										>
											Categories cannot be empty!
										</p>
									) : null}
								</Space>
							</Form.Item>
						) : null}

						{newswireArticleState === false &&
							categoriesModalOpen && (
								<ManageModal
									open={categoriesModalOpen}
									all_data={allCategories}
									set_all_data={setAllCategories}
									new_data={newCategories}
									set_new_data={setNewCategories}
									// wordpress_data_id={id}
									handleCancel={handleCategoriesModalCancel}
									type={"categories"}
								/>
							)}

						{newswireArticleState === false ? (
							<Form.Item>
								<label className="tagLabel">Tag(s)</label>
								<Space
									className="tagInputContainer"
									direction="vertical"
								>
									<Select
										getPopupContainer={(trigger: any) =>
											trigger.parentElement
										}
										className={`tagInput${
											tagEmptyState === true
												? "-true"
												: ""
										}`}
										mode="multiple"
										style={{ width: "100%" }}
										placeholder="Please select"
										value={newTags}
										options={tagsOption}
										filterOption={(
											inputValue: string,
											option: any
										) =>
											option.label
												.toLowerCase()
												.includes(
													inputValue.toLowerCase()
												)
										}
										showArrow
										onChange={(selectedValues: any) =>
											setNewTags([...selectedValues])
										}
										dropdownStyle={{
											zIndex: "1000",
											transition: "max-height 0.01s",
										}}
										dropdownRender={(option: any) => (
											<>
												{option}
												<Divider
													style={{
														margin: "auto",
														borderTop:
															"1px solid grey",
													}}
												/>
												<Button
													className="manageTagsButton"
													type="text"
													onClick={() =>
														setTagsModalOpen(true)
													}
												>
													Manage Tags
												</Button>
											</>
										)}
									/>
									{tagEmptyState === true ? (
										<p
											style={{
												color: "tomato",
												marginLeft: "0.5%",
											}}
										>
											Tag cannot be empty!
										</p>
									) : null}
								</Space>
							</Form.Item>
						) : null}
						{newswireArticleState === false && tagsModalOpen && (
							<ManageModal
								open={tagsModalOpen}
								all_data={allTags}
								set_all_data={setAllTags}
								new_data={newTags}
								set_new_data={setNewTags}
								// wordpress_data_id={id}
								handleCancel={handleTagsModalCancel}
								type={"tags"}
							/>
						)}

						{newswireArticleState === false ? (
							<Form.Item>
								<label className="featuredImageLabel">
									Featured Image
								</label>
								{newImages.length == 0 ? (
									<div
										className="featuredImageInputContainer"
										style={{ textAlign: "center" }}
									>
										<Empty
											image={Empty.PRESENTED_IMAGE_SIMPLE}
										/>
									</div>
								) : (
									<div
										className="featuredImageInputContainer"
										style={{
											minHeight: "45vh",
											height: "45vh",
											overflowY: "auto",
											resize: "vertical",
										}}
									>
										{newImages.map(
											(col: any, rowIndex: any) => (
												<Row
													key={uuidv4()}
													className="rowContainer"
												>
													{col.map(
														(
															img: any,
															colIndex: any
														) => (
															<Col
																key={img._id}
																className={`colContainer${
																	img.selected ==
																	true
																		? "-selected"
																		: ""
																}`}
																onClick={() =>
																	handleSelectFeatureImage(
																		rowIndex,
																		colIndex
																	)
																}
															>
																<img
																	src={
																		img.src
																	}
																	className="featureImage"
																/>
																{img.selected ==
																true ? (
																	<p className="featureImageSelected">
																		Selected
																		<CheckOutlined
																			style={{
																				float: "right",
																				marginTop:
																					"2%",
																				fontWeight:
																					"bold",
																			}}
																		/>
																	</p>
																) : null}
															</Col>
														)
													)}
												</Row>
											)
										)}
									</div>
								)}
							</Form.Item>
						) : null}

						<Form.Item>
							<label className="contentLabel">Content</label>
							<div
								className="contentInputContainer"
								onClick={handleImageClick}
							>
								<ReactQuill
									ref={(ref) => {
										editorRef.current = ref;
									}}
									className={`contentInput${
										contentEmptyState == true ? "-true" : ""
									}`}
									theme="snow"
									value={newContent}
									onChange={handleOnchange}
									readOnly={quillBoxState}
									placeholder="Enter some article content..."
									modules={modules}
								/>
								{contentEmptyState === true ? (
									<p
										style={{
											color: "tomato",
											marginLeft: "0.5%",
										}}
									>
										Content cannot be empty!
									</p>
								) : null}
							</div>
						</Form.Item>
						{newswireArticleState === false &&
						imagesModalOpen == true ? (
							<div id="imagesModal">
								<Space id="imagesModalTitle" size={20}>
									<p style={{ display: "inline" }}>
										Click here and drag to move the modal
									</p>
									<CloseCircleOutlined
										className="imagesModalCloseIcon"
										onClick={handleImagesModal}
									/>
								</Space>
								{newImages.length == 0 ? (
									<div style={{ textAlign: "center" }}>
										<Empty
											image={Empty.PRESENTED_IMAGE_SIMPLE}
										/>
									</div>
								) : (
									<div className="imagesModalContent">
										{newImages.map(
											(col: any, rowIndex: any) => (
												<Row
													key={uuidv4()}
													className="imagesModalRowContainer"
												>
													{col.map(
														(
															img: any,
															colIndex: any
														) => (
															<Col
																key={img._id}
																className="imagesModalColContainer"
																onClick={() =>
																	insertImages(
																		img.src
																	)
																}
															>
																<img
																	src={
																		img.src
																	}
																	className="images_in_modal"
																/>
															</Col>
														)
													)}
												</Row>
											)
										)}
									</div>
								)}
							</div>
						) : null}
					</Form>
				)}
			</Modal>
		</>
	);
};

export default Editorial;
