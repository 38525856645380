import React, { useState } from "react";
import { Table, Button } from "antd";

const NewswireTable = (props: any) => {
	// const [modalVisible, setModalVisible] = useState(false);
	// const [selectedArticlesIds, setSelectedArticlesIds] = useState<any[]>([]);

	// Define the rowSelection object
	// const rowSelection = {
	// 	onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
	// 		// Save the ids of selected articles
	// 		setSelectedArticlesIds(selectedRows.map((row) => row.key));
	// 	},
	// };

	return (
		<>
			<Table
				dataSource={props.dataSource}
				columns={props.columns} // {modifiedColumns} // Use the modified columns here
				size={"small"}
				bordered={true}
				rowClassName={(record: any, index: any) =>
					index % 2 === 0 ? "table-row-light" : "table-row-dark"
				}
				rowSelection={props.rowSelection}
				pagination={props.pagination}
				loading={props.loading}
			/>
		</>
	);
};

export default NewswireTable;
