import { useState, useEffect } from "react";
import { Modal, Checkbox, Divider, Button } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import Events from "./../lib/events";

const DistributorsNewswireModal: any = (props: any) => {
  const CheckboxGroup = Checkbox.Group;
  const distributors = [
    "Acquiremedia (XML)",
    "Factiva (XML)",
    "Lexisnexis (XML)",
    "Proquest (XML)",
    "Nikkei (XML)",
    "Fiona Fox (XML)",
    "James Harker (XML)",
    "Test Distribution 1 (XML)",
  ];
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const [data, setData] = useState<{
    userId: string;
    article_ids: any[];
    distribution_name: CheckboxValueType[];
    source: string;
  }>({
    userId: sessionStorage.getItem("email") || "",
    article_ids: props.articles_id,
    distribution_name: [],
    source: "newswire",
  });

  //use useEffect to update the data when the props change
  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      article_ids: props.articles_id,
      source: "newswire",
    }));
    setIndeterminate(false);
  }, [props.articles_id]);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setData({ ...data, distribution_name: list });
    setIndeterminate(!!list.length && list.length < distributors.length);
    setCheckAll(list.length === distributors.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? distributors : []);
    setData({
      ...data,
      distribution_name: e.target.checked ? distributors : [],
    });
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleOk = () => {
    props.onClose();
    Events.emit("distribute_articles", data);
  };

  const handleCancel = () => {
    props.onClose();
    setCheckedList([]);
    setIndeterminate(true);
    setCheckAll(false);
  };

  return (
    <div>
      <Modal
        title="Select Distribution Channels"
        visible={props.visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Send"
        okButtonProps={{ disabled: checkedList.length === 0 }}
      >
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          <b>Send to All</b>
        </Checkbox>
        <Divider />
        <CheckboxGroup
          style={{ display: "flex", flexDirection: "column" }}
          options={distributors}
          value={checkedList}
          onChange={onChange}
        />
      </Modal>
    </div>
  );
};

export default DistributorsNewswireModal;
